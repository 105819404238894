import { checkPermissionAny } from '../../../../configs/permissionGate'

const module = 'taskapprovalrequest'
export const checkPermission = (slug: string) => {
  const permissionSlug = `${slug}_${module}`
  return checkPermissionAny(permissionSlug)
}
export const checkDetailPermission = (slug: string, subModule?: string) => {
  const permissionSlug = `${slug}_${module}_${subModule}`
  return checkPermissionAny(permissionSlug)
}
