import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Icons from '../components/common/icons/index'
import { router_config } from '../configs/route.config'
import Profile from '../pages/profile'

type Props = {
  userData: any
  handleLogout: () => void
}

export default function HeaderMenu({ userData, handleLogout }: Props) {
  const [openMenu, setOpenMenu] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const navigate = useNavigate()
  const menuRef = useRef<HTMLDivElement | null>(null)

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
    // setFranchiseeMenu(false)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(true)
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const stringToColor = (string: string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value?.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  // function stringAvatar(name: string) {
  //   const splitName = name?.split(' ')

  //   return {
  //     bgcolor: stringToColor(name),
  //     name: splitName
  //       .filter((n) => n) // Filter out any empty strings
  //       .map((n) => n[0].toUpperCase()) // Capitalize the first letter
  //       .slice(0, 2) // Take only the first two elements
  //       .join(''), // Join them together
  //   }
  // }
  function stringAvatar(name: string) {
    if (!name) {
      return {
        bgcolor: stringToColor(''),
        name: '',
      }
    }

    const splitName = name.split(' ')

    return {
      bgcolor: stringToColor(name),
      name: splitName
        .filter((n) => n) // Filter out any empty strings
        .map((n) => n[0].toUpperCase()) // Capitalize the first letter
        .slice(0, 2) // Take only the first two elements
        .join(''), // Join them together
    }
  }

  // const navigateToProfile = () => {
  //   toggleMenu()
  //   if (location.pathname !== router_config.USER_PROFILE.path) {
  //     navigate(`${router_config.USER_PROFILE.path}`)
  //   }
  // }
  return (
    <div
      ref={menuRef}
      className="dropdown text-black dark:text-white  dark:bg-[#424242] bg-transparent"
    >
      <div className="dropdown-toggle bg-transparent">
        <div
          onClick={toggleMenu}
          className={`w-10 h-10 rounded-full p-2 flex justify-center items-center border border-formBorder text-xxs leading-4 font-semibold bg-white cusor-pointer`}
        >
          {stringAvatar(userData?.name as string)?.name}
        </div>
      </div>

      {openMenu && (
        <div
          className={`dropdown-menu flex flex-col gap-1 origin-top ${'text-black dark:text-white'} p-4 min-w-[340px] text-sm font-normal   tracking-[0.24px]  rounded-[8px]  absolute right-0 mt-[20px] top-8 w-auto shadow-modalShadow bg-white  focus:outline-none dark:bg-[#424242]  dark:text-white `}
        >
          <div
            className={`p-2 leading-7 hover:bg-background  flex min-w-[300px]  justify-between items-center gap-3 w-max   `}
          >
            <div
              className={`   flex items-center  w-full tracking-[0.24px]  leading-4`}
            >
              <span
                className={`w-10 h-10 rounded-full flex justify-center border items-center bg-[${
                  stringAvatar(userData.name as string)?.bgcolor
                }]`}
              >
                {stringAvatar(userData.name as string)?.name}
              </span>
              <div className=" flex flex-col pl-1.5">
                <span className=" ">{userData.name}</span>
              </div>
            </div>
          </div>

          <div className="h-px w-full bg-formBorder"></div>

          <div
            className={`p-2 leading-7 hover:bg-background cursor-pointer`}
            onClick={() => {
              handleOpenDrawer()
            }}
          >
            <div
              className={`${'text-black dark:text-white'}  flex items-center w-full tracking-[0.24px]  leading-4`}
            >
              <Icons name={'profile_icon'} />
              <span className="pl-1.5 ">Manage Profile </span>
            </div>
          </div>
          <a
            href="#/"
            className={`p-2 leading-7 hover:bg-background `}
            onClick={() => {
              handleLogout(), navigate(`${router_config.USER_PROFILE.path}`)
            }}
          >
            <div
              className={`${'text-black dark:text-white'}   flex items-center w-full tracking-[0.24px]  leading-4`}
            >
              <Icons name={'logout_icon'} />

              <span className="pl-1.5 ">{'Log out'}</span>
            </div>
          </a>
        </div>
      )}
      <Profile
        isOpen={openDrawer}
        handleProfileClose={() => setOpenDrawer(false)}
      />
    </div>
  )
}
