import { getNestedProperty } from '../../../../utilities/parsers'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = () => {
  const createRenderCell =
    (key: string, custom_data?: string) => (row: any) => {
      if (custom_data == 'datetime_created') {
        return {
          cell: row?.datetime_created
            ? convertUTCtoBrowserTimeZone(row?.datetime_created)
            : '',
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else {
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
    }

  const column = [
    {
      title: 'Current status',
      ...defaultColumnProps3,
      renderCell: createRenderCell('current_status.display_name'),
      customCell: true,
    },
    {
      title: 'Created By',
      field: 'created_by',
      ...defaultColumnProps3,
    },
    {
      title: 'Created Date',
      field: 'datetime_created',
      renderCell: createRenderCell('datetime_created', 'datetime_created'),
      customCell: true,
      ...defaultColumnProps3,
    },
  ]

  return column
}
