import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import Icons from '../../../../components/common/icons/index'
import DialogModal from '../../../../components/common/modal/DialogModal'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { getErrorMessage, numberFormat } from '../../../../utilities/parsers'
import AllocatePayments from '../../../order/allocatePayments'
import {
  getServiceDetails,
  unAllocatePayment,
  useServicePaymentsDetails,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import ViewAllocation from '../../viewPayment'
import { getColumns } from './paymentsColumns'

const ServicePayments = () => {
  const { setActiveTab, handleParentCallBack, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    disabled: boolean
  }>()
  const [isAllocatePaymentOpen, setIsAllocatePaymentOpen] = useState(false)
  const [allocateData, setAllocateData] = useState({})
  const [unallocate, setUnallocate] = useState(false)
  const [allocateId, setAllocateId] = useState({})
  const [viewPayment, setViewPayment] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [availableAllocation, setAvailableAllocation] = useState('')

  const { enqueueSnackbar } = useSnackbarManager()

  const params = useParams()
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    const { data } = await getServiceDetails(params.id)
    setAllocateData(data)
    setAvailableAllocation(
      numberFormat(data?.order?.paid_amount - data?.order?.allocated_amount)
    )
  }
  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  // const handleAllocatePayment = () => {
  //   setIsAllocatePaymentOpen(true)
  // }
  const handleViewPayment = (data: any) => {
    setPaymentData(data)
    setAllocateId(data?.id)
    setViewPayment(true)
  }
  const handleUnAllocatePaymentDialog = (data: any) => {
    setUnallocate(true)
    setAllocateId(data.id)
  }
  const handleRefresh = () => {
    getData()
    handleParentCallBack?.()
  }

  const { data, isFetching, refetch } = useServicePaymentsDetails(
    params?.id ?? '',
    {}
  )
  const onViewAction = (r: any, from: string) => {
    if (from == 'accountName') {
      return `${router_config.ACCOUNTS.path}/${r.order_details?.account?.id}/summary`
    } else if (from == 'orderId') {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${r.order_details?.id}/summary`
    } else if (from == 'allocateId') {
      handleViewPayment(r)
    }
  }

  const handleCallback = () => {
    refetch()
    handleRefresh()
  }

  const handleUnAllocatePayment = () => {
    unAllocatePayment({
      allocation_id: allocateId ?? '',
    })
      .then(() => {
        setUnallocate(false)
        handleCallback?.()
        enqueueSnackbar('Payment Un Allocated successfully', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
      })
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          columns={
            getColumns({
              onViewAction: onViewAction,
            }) as any
          }
          // tableHeaderActions={
          //   <div className="flex gap-2">
          //     <Button
          //       label={'Allocate Payment'}
          //       disabled={disabled}
          //       hidden={!checkDetailPermission('add', MODULES.payment)}
          //       icon={'plus'}
          //       onClick={() => handleAllocatePayment()}
          //     />
          //   </div>
          // }
          actionProps={[
            {
              icon: <Icons name="eye" />,
              action: (row) => handleViewPayment(row),
              title: 'view-payment',
              hidden: !checkDetailPermission('detail', MODULES.payment),
              toolTip: 'View',
            },
            {
              icon: <Icons name="un-allocate-payment" />,
              action: (row) => handleUnAllocatePaymentDialog(row),
              title: 'un-allocate-payment',
              hidden:
                !checkDetailPermission('unallocate', MODULES.payment) ||
                disabled ||
                true,

              toolTip: 'Un-allocate Payment',
            },
          ]}
          columnToggle
        />
      </div>

      <AllocatePayments
        isDrawerOpen={isAllocatePaymentOpen}
        subSection={true}
        handleClose={() => setIsAllocatePaymentOpen(false)}
        handleCallback={handleCallback}
        id={params.id}
        datas={allocateData}
        availableAllocation={availableAllocation}
      />
      <ViewAllocation
        isDrawerOpen={viewPayment}
        handleClose={() => setViewPayment(false)}
        handleCallback={handleRefresh}
        handleUnAllocatePayment={undefined}
        datas={paymentData}
      />
      <DialogModal
        isOpen={unallocate}
        onClose={() => setUnallocate(false)}
        title={'Are you sure?'}
        secondaryAction={() => setUnallocate(false)}
        secondaryActionLabel="No, Cancel"
        onSubmit={() => handleUnAllocatePayment()}
        actionLabel="Okay"
        body={
          <InfoBox
            content={
              'Are you sure you want to un-allocate this payment from the service?'
            }
          />
        }
      />
    </>
  )
}

export default ServicePayments
