import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import apiUrl from '../../../../apis/api.url'
import { createNote, updateNote } from '../../../../apis/common.apis'
import InfoBox from '../../../../components/app/alertBox/infoBox'
import CreateEventLogs from '../../../../components/app/notes/createNote'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import DialogModal from '../../../../components/common/modal/DialogModal'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { useAuthStore } from '../../../../store/authStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import ManageActivityRequests from '../../../activities/details/requests/manage'
import AddActivity from '../../addActivity'
import {
  activityReArrange,
  delete_activity,
  getServiceActivityDetails,
  getServiceDetails,
} from '../../api'
import AssignActivity from '../../assign'
import { checkDetailPermission } from '../../permission'
import { getColumns } from './activityColumns'

const ActivityProgress = () => {
  const { setActiveTab, handleParentCallBack, disabled, detailData } =
    useOutletContext<{
      setActiveTab: (id: string) => void
      handleParentCallBack: () => void
      disabled: boolean
      detailData: any
    }>()
  const [addActivity, setAddActivity] = useState(false)
  const [data, setData] = useState<any>()
  const [orderId, setOrderId] = useState<any>()
  const [eventLogOpen, setEventLogOpen] = useState(false)
  const [activityId, setActivityId] = useState<any>()
  const [deleteActivity, setDeleteActivity] = useState(false)
  const [assignOpen, setAssignOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [orderCre, setOrderCre] = useState<any>({})
  const [activityRow, setActivityRow] = useState<any>()
  const [assingee, setAssignee] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const { userData } = useAuthStore()
  const [isTeamLead, setIsTeamLead] = useState(false)
  const [pageParams, setPageParams] = useState<any>({ search: '' })

  const { enqueueSnackbar } = useSnackbarManager()
  const handleAssignAction = (row: any) => {
    if (row?.assignee?.id) {
      setAssignee({
        id: row?.assignee?.id,
        name: row?.assignee?.name,
        user: row?.assignee?.user,

        employee_profile: row?.assignee?.employee_profile,
      })
    }
    setActivityId(row?.id)
    setAssignOpen(true)
  }
  const handleClose = () => {
    setAssignOpen(false)
    setAssignee(undefined)
    setActivityId(undefined)
  }
  const params = useParams()
  useEffect(() => {
    getData()

    if (detailData?.assigned_vendor_team_lead_data?.id == userData?.id)
      setIsTeamLead(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.search])

  const handleSearchValue = (value?: string) => {
    setPageParams({ ...pageParams, search: value })
  }

  const getData = async () => {
    setIsLoading(true)
    const { data } = await getServiceDetails(params.id)
    if (data?.order?.id) {
      setOrderId(data?.order?.id)
      getActivityList(data?.order?.id)
      setOrderCre({
        id: data?.order?.order_cre_id,
        name: data?.order?.order_cre,
      })
    }
  }

  const getActivityList = (order_id?: string) => {
    getServiceActivityDetails(
      order_id ?? orderId,
      params?.id ?? '',
      pageParams
    ).then((res) => {
      setData(res)
      setIsLoading(false)
    })
  }

  const handleCallback = () => {
    getData()
    handleParentCallBack?.()
  }

  const addNotes = (id: string) => {
    setActivityId(id)
    setEventLogOpen(true)
  }

  const handleNoteClose = () => {
    handleCallback()
    setEventLogOpen(false)
  }

  const handleAction = (data: any) => {
    setIsOpen(true)
    setActivityRow(data)
    setActivityId(data?.id)
  }

  const onNoteSubmit = (data?: any) => {
    const apiFunction = data?.id ? updateNote : createNote
    apiFunction(
      `${apiUrl.SERVICE_DETAIL_NOTES_URL}task/`,
      activityId as string,
      data,
      false,
      'notes'
    )
      .then(() => {
        enqueueSnackbar(
          data?.id ? 'Note Updated Successfully' : 'Note Created Successfully',
          { variant: 'success' }
        )
        handleNoteClose()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }
  const handleDeleteActivtyDialog = (id: string) => {
    setDeleteActivity(true)
    setActivityId(id)
  }

  const handleDeleteActivity = () => {
    delete_activity(activityId ?? '')
      .then(() => {
        setDeleteActivity(false)
        handleCallback?.()
        enqueueSnackbar('Activity Deleted successfully', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
      })
  }

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onViewAction = (r: any, from: string) => {
    if (from == 'taskName') {
      return `${router_config.ACTIVITIES.path}/${r.id}/summary`
    } else if (from == 'notes') {
      return `${router_config.ACTIVITIES.path}/${r.id}/notes`
    }
  }

  const handleRequestClose = () => {
    setIsOpen(false)
  }
  const handleMoveDown = (row: any) => {
    if (row.id)
      activityReArrange(orderId, params?.id as string, row.id, {
        new_sort_order: row.sort_order + 1,
      }).then(() => {
        getActivityList()
      })
  }
  const handleMoveUp = (row: any) => {
    if (row.id)
      activityReArrange(orderId, params?.id as string, row.id, {
        new_sort_order: row.sort_order - 1,
      }).then(() => {
        getActivityList()
      })
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          handleSearchValue={handleSearchValue}
          searchValue={pageParams.search}
          isLoading={isLoading}
          columns={
            getColumns({
              onViewAction: onViewAction,
            }) as any
          }
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                label={'Add New'}
                disabled={disabled}
                icon={'plus'}
                onClick={() => setAddActivity(true)}
                hidden={!checkDetailPermission('add', MODULES.activity)}
              />
            </div>
          }
          actionProps={[
            ...(disabled
              ? []
              : [
                  {
                    icon: <Icons name="drop-arrow" />,
                    action: (row: any) => handleMoveDown(row),
                    title: 'table-delete',
                    toolTip: 'Move Down',
                    hidden: disabled,
                    hide: (rowData: any, rowIndex?: number) =>
                      rowIndex && rowIndex >= data?.length - 1 ? true : false,
                  },
                  {
                    icon: <Icons name="up-arrow" />,
                    action: (row: any) => handleMoveUp(row),
                    title: 'table-delete',
                    toolTip: 'Move Up',
                    hide: (rowData: any, rowIndex?: number) =>
                      rowIndex == 0 ? true : false,
                    hidden: disabled,
                  },
                  {
                    icon: <Icons name="table-delete" />,
                    action: (row: any) => handleDeleteActivtyDialog(row.id),
                    title: 'table-delete',
                    toolTip: 'Delete',
                    hidden:
                      !checkDetailPermission('delete', MODULES.activity) ||
                      disabled,
                    hide: (rowData: any) =>
                      rowData?.stakeholder?.manage_type == 'franchisee'
                        ? true
                        : false,
                  },
                  {
                    icon: <Icons name="activities" />,
                    action: (row: any) => handleAssignAction(row),
                    title: 'assign-ativities',
                    toolTip: 'Assign Activity',
                    // hidden:
                    //   !checkDetailPermission('assign', MODULES.activity) ||
                    // disabled ||
                    // !isTeamLead,
                    hide: (row: any) => {
                      const isStatusCompleted =
                        row?.status?.code === 'completed'

                      const orderLock =
                        blockActionsbyStatus(
                          row?.item?.order?.order_status?.code,
                          [
                            'cancelled',
                            'order_cancelled',
                            'rejected',
                            'order_completed_payment_done',
                          ]
                        ) && row?.item?.order?.is_locked
                      const serviceLock =
                        blockActionsbyStatus(
                          row?.item?.service_status?.status?.category?.code,
                          ['cancelled', 'order_cancelled', 'rejected']
                        ) && row?.item?.order?.is_locked
                      const notPermitted =
                        orderLock ||
                        serviceLock ||
                        row?.stakeholder?.manage_type == 'franchisee'
                      // assign_orderitem_orderitemtaskemployee
                      const hasPrevilage =
                        row?.can_change_assignee ||
                        checkDetailPermission('assign', MODULES.activity)

                      return notPermitted || !hasPrevilage || isStatusCompleted
                    },
                  },
                  {
                    icon: <Icons name="raise-request" />,
                    action: (row: any) => handleAction(row),
                    title: 'raise request',
                    toolTip: 'Raise  Request',
                    hidden:
                      !checkDetailPermission('request', MODULES.activity) ||
                      disabled,
                    hide: (rowData: any) =>
                      rowData?.stakeholder?.manage_type == 'franchisee'
                        ? true
                        : false,
                  },
                  {
                    icon: <Icons name="add-notes" />,
                    action: (row: any) => addNotes(row.id),
                    hidden:
                      !checkDetailPermission('note', MODULES.activity) ||
                      disabled,

                    title: 'add-notes',
                    toolTip: 'Add  Notes',
                    hide: (rowData: any) =>
                      rowData?.stakeholder?.manage_type == 'franchisee'
                        ? true
                        : false,
                  },
                ]),
          ]}
          columnToggle
        />
      </div>
      {addActivity && (
        <AddActivity
          isDrawerOpen={addActivity}
          subSection={true}
          handleClose={() => setAddActivity(false)}
          handleCallback={getActivityList}
          id={params.id}
          orderCre={orderCre}
          orderId={orderId}
          isTeamLead={isTeamLead}
        />
      )}

      <CreateEventLogs
        isDrawerOpen={eventLogOpen}
        onSubmit={onNoteSubmit}
        handleClose={handleNoteClose}
      />
      <AssignActivity
        isDrawerOpen={assignOpen}
        handleClose={handleClose}
        handleCallback={handleCallback}
        subSection={true}
        orderId={orderId}
        serviceId={params.id}
        taskId={activityId}
        assingee={assingee}
      />
      <ManageActivityRequests
        isDrawerOpen={isOpen}
        stakeholder_id={activityRow?.stakeholder?.id}
        service_id={params?.id}
        order_id={orderId}
        task_id={activityId}
        handleClose={() => handleRequestClose()}
        handleCallback={handleCallback}
      />
      <DialogModal
        isOpen={deleteActivity}
        onClose={() => setDeleteActivity(false)}
        title={'Are you sure?'}
        secondaryAction={() => setDeleteActivity(false)}
        secondaryActionLabel="No, Cancel"
        onSubmit={() => handleDeleteActivity()}
        actionLabel="Okay"
        body={
          <InfoBox
            content={
              'Are you sure you want to delete this activity from the service?'
            }
          />
        }
      />
    </>
  )
}

export default ActivityProgress
