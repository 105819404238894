import { useMutation } from '@tanstack/react-query'

import { QueryParams } from '../common/types'
import { useSnackbarManager } from '../components/common/snackbar'
import { useAuthStore } from '../store/authStore'
import { getErrorMessage, parseQueryParams } from '../utilities/parsers'
import {
  deleteData,
  getData,
  patchData,
  postData,
  postFormData,
  updateData,
  updateFromData,
} from './api.helpers'
import apiUrl from './api.url'

interface Params {
  [key: string]: string | number | boolean
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
export const getImpersonate = (id: string) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}permissions/${id}/`)
}

export const exportDocument = (data: any, url: any) =>
  getData(`${apiUrl[url]}${parseQueryParams(data)}`)

export const bulkAssignCre = (data: any, url: any) =>
  updateData(`${apiUrl[url]}`, data)

export const getEmployeeList = (params: Params) => {
  const vendor_id = useAuthStore?.getState()?.userData?.vendor || ''

  const res = getData(
    `${apiUrl.VENDOR_URL}${vendor_id}/employees${parseQueryParams(params)}`
  ).then((res) => res.data)
  return res
}
export const getUserList = (params: Params) => {
  const res = getData(
    `${apiUrl.USER_URL}assignee-list/${parseQueryParams(params)}`
  ).then((res) => res.data)
  return res
}
export const getFranchiseeEmployeeList = (params: Params) =>
  getData(`${apiUrl.EMPLOYEE_LIST_URL}${parseQueryParams(params)}`)
export const getFranchEmployeeList = (params: Params, id: string) => {
  const res = getData(
    `${apiUrl.ORDER_LIST_URL}${id}/get_associated_employees${parseQueryParams(params)}`
  ).then((res) => res.data)
  return res
}
export const changeAccountOwner = (data: any, id: string) => {
  const res = patchData(
    `${apiUrl.ACCOUNT_LIST_URL}${id}/change_account_owner/`,
    data
  ).then((res) => res.data)
  return res
}
export const getAssigneeEmployeeList = (params: Params) =>
  getData(
    `${apiUrl.EMPLOYEE_LIST_URL}service-assignee${parseQueryParams(params)}`
  )

export const getLeadQualityList = () => getData(`${apiUrl.LEAD_QUALITY}`)
export const getNotificationTypesList = (params: Params) =>
  getData(`${apiUrl.NOTIFICATION_TYPE}${parseQueryParams(params)}`)
export const getAllEmployeeList = (key?: string) =>
  getData(`${apiUrl.VENDOR_EMPLOYEE_LIST_URL}?view=${key ?? 'all'}`)

export const getTeamList = (params: Params) =>
  getData(`${apiUrl.TEAMS_LIST_URL}${parseQueryParams(params)}`)

export const getVendorTeamList = (vendorId: string, params: Params) =>
  getData(`${apiUrl.VENDOR_URL}${vendorId}/teams/${parseQueryParams(params)}`)

export const getConstCat = (params: Params) =>
  getData(`${apiUrl.CONSTITUTION_CATEGORY}${parseQueryParams(params)}`)

export const getbusinsector = (params: Params) =>
  getData(`${apiUrl.BUSINESS_SECTOR}${parseQueryParams(params)}`)

export const getMasterDataList = () => getData(apiUrl.MASTER_DATA)
export const getCountryState = () => getData(apiUrl.STATE_LIST)

export const getLeadStatusList = (id?: string, data?: any) =>
  getData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/status${parseQueryParams(data)}`)

export const getAccounts = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.ACCOUNT_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}
export const getAllAccounts = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.ACCOUNT_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response
}
export const getAccountsById = async (id: any) => {
  const response = getData(`${apiUrl.ACCOUNT_LIST_URL}${id}`)
  return response
}

export const getContacts = (input?: Params) => {
  return getData(`${apiUrl.CONTACT_LIST_URL}${parseQueryParams(input)}`)
}
export const getService = (input?: Params) => {
  return getData(`${apiUrl.SERVICE_LIST_URL}${parseQueryParams(input)}`)
}
export const getPaymentOutMethods = (params: Params) => {
  const url = buildUrlWithParams(apiUrl.PAYMENT_OUT_METHODS, params)
  return getData(url)
}
export const fetchVendorBankAccount = async (data: any) => {
  const url = buildUrlWithParams(
    `${apiUrl.VENDOR_URL}${data?.id}/bank_accounts/`,
    {
      ...data?.input,
    }
  )
  const response = await getData(url)
  return response
}
export const getPaymentOutList = (params: Params) => {
  const url = buildUrlWithParams(apiUrl.PAYMENT_OUT_LIST, params)
  return getData(url)
}

export const getMinServiceList = (input?: Params) => {
  return getData(`${apiUrl.SERVICE_LIST_URL}list/${parseQueryParams(input)}`)
}

export const getServiceList = (id?: string, pageParams?: any) => {
  return getData(
    `${apiUrl.ORDER_LIST_URL}${id}/items/available_services${parseQueryParams(
      pageParams
    )}`
  )
}

export const getOrderList = (data: any) => {
  return getData(`${apiUrl.ORDER_LIST_URL}order-list/${parseQueryParams(data)}`)
}

export const fetchAccountContacts = async (pageParams: any, id: string) => {
  const response = await getData(
    `${apiUrl.ACCOUNT_CONTACT}/${id}/contacts${parseQueryParams(pageParams)}`
  )
  return response
}

//Common Notes

export const getNotes = (url: string, id: string, addUrl?: string) => {
  return getData(`${url}${id}/${addUrl ?? 'notes'}`)
}
export const getHealthCheck = () => {
  return getData(`${apiUrl.HEALTH_CHECK}`)
}
export const createNote = (
  url: string,
  id: string,
  data: any,
  urlalter: boolean,
  addUrl?: string
) => {
  return postData(
    `${url}${id}/${addUrl ?? 'notes'}${urlalter ? '/' : ''}`,
    data
  )
}

export const updateNote = (
  url: string,
  id: string,
  data: any,
  urlalter: boolean,

  addUrl?: string
) => {
  return updateData(
    `${url}${id}/${addUrl ?? 'notes'}/${data.id}${urlalter ? '/' : ''}`,
    data
  )
}
export const createNoteComment = (
  url: string,
  id: string,
  data: any,
  parent: string,
  urlalter: boolean,
  addUrl?: string
) => {
  return postData(
    `${url}${id}/${addUrl ?? 'note'}/${parent}/comments${urlalter ? '/' : ''}`,
    data
  )
}
export const updateNoteComment = (
  url: string,
  id: string,
  data: any,
  parent: string,
  commentId: string,
  urlalter: boolean,
  addUrl?: string
) => {
  return updateData(
    `${url}${id}/${addUrl ?? 'note'}/${parent}/comments/${commentId}${
      urlalter ? '/' : ''
    }`,
    data
  )
}

//Common File

export const mergeLeads = (data: any, id?: string) => {
  return updateData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/merge`, data)
}

// FILES AND ATTACHMENTS

export const getFilesAttachments = (data?: any) => {
  const response = getData(
    `${apiUrl.LEAD_FILES_ATTACHMENTS}${parseQueryParams(data)}`
  )
  return response
}

export const getDocumentType = () => {
  const response = getData(apiUrl.LEAD_DOCUMENT_TYPE)
  return response
}

export const createLeadAttachments = (data: any) => {
  return postFormData(apiUrl.LEAD_FILES_ATTACHMENTS, data)
}
export const useFileAttachments = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createLeadAttachments, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const useCreateTask = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createtask, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const useUpdateTask = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(updateTask, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Updated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const attachmentsDelete = (data: any) => {
  return deleteData(`${apiUrl.LEAD_FILES_ATTACHMENTS}/${data}`)
}

//Event Apis

export const getEventLogs = (data?: any) => {
  const response = getData(`${apiUrl.EVENTS_URL}${parseQueryParams(data)}`)
  return response
}

export const createEventLogs = (data: any) => {
  return postFormData(apiUrl.EVENTS_URL, data)
}
export const getEventDetails = (id: string) => {
  return getData(`${apiUrl.EVENTS_URL}/${id}`)
}

export const getEventType = (data?: any) => {
  return getData(apiUrl.EVENT_TYPE + parseQueryParams(data))
}

export const deleteEvent = (id: string) => {
  return deleteData(`${apiUrl.EVENTS_URL}/${id}`)
}

export const updateEventLog = (id: string, data: any) => {
  return updateFromData(`${apiUrl.EVENTS_URL}/${id}`, data)
}

export const deleteEventLogFile = (id: string) => {
  return deleteData(`${apiUrl.EVENT_LOG}/${id}/delete-attachment
`)
}
//TASk

export const getasks = (data?: any) => {
  const response = getData(`${apiUrl.TASK_URL}${parseQueryParams(data)}`)
  return response
}

export const createtask = (data: any) => {
  return postFormData(apiUrl.TASK_URL, data)
}
export const getTaskDetails = (id: string) => {
  return getData(`${apiUrl.TASK_URL}/${id}`)
}
export const getTaskCategory = (data?: any) => {
  data = data ?? { type: 'dropdown' }
  return getData(`${apiUrl.TASK_CATEGORY}${parseQueryParams(data)}`)
}
export const getTaskStatus = (data?: any) => {
  data = data ?? {}
  return getData(`${apiUrl.TASK_STATUS}${parseQueryParams(data)}`)
}
export const getPriorityList = () => {
  // data = data ?? { type: 'dropdown' }
  return getData(apiUrl.PRIORITY)
}
export const changeServicePriority = (data: any, id?: string) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${id}/change-priority`, data)
}
export const getServicePriorityList = () => {
  // data = data ?? { type: 'dropdown' }
  return getData(apiUrl.SERVICE_PRIORITY)
}
export const deleteTask = (id: string) => {
  return deleteData(`${apiUrl.TASK_URL}/${id}`)
}
export const deleteLead = (id: string) => {
  return deleteData(`${apiUrl.LEAD_LIST_URL}/${id}`)
}

export const updateTask = ({ id, data }: any) => {
  return updateFromData(`${apiUrl.TASK_URL}/${id}`, data)
}

export const getDeliverablesList = (input?: any) => {
  return getData(`${apiUrl.DELIVERABLES}${parseQueryParams(input)}`)
}
export const getCollectablesList = (input?: any) => {
  return getData(`${apiUrl.COLLECTIBLES}${parseQueryParams(input)}`)
}

// common payment

export const getCommonData = (url: string) => {
  return getData(`${url}`)
}

export const updatePaymentDetails = (url: string, data: any) => {
  return updateData(`${url}`, data)
}
export const getPaymentDetails = (id: any) => {
  return getData(`${apiUrl.PAYMENT_LIST}${id}`)
}

export const sendReceipt = (id: string, data: any) => {
  return postData(`${apiUrl.PAYMENT_LIST}${id}/send-receipt`, data)
}
export const getAllPermision = () => {
  return getData(apiUrl.PERMISSSION_LIST_URL)
}

export const getTablePreferences = () => {
  return getData(`${apiUrl.TABLE_PREFRENCES}?choice=table_preference`)
}

export const saveTablePreferences = (data: any) => {
  return postData(apiUrl.TABLE_PREFRENCES, data)
}
export const updateTablePreferences = (id: string, data: any) => {
  return updateData(`${apiUrl.TABLE_PREFRENCES}${id}`, data)
}
export const getTablePreference = (id: string) => {
  return getData(`${apiUrl.TABLE_PREFRENCES}${id}/`)
}

export const getNotificationList = (value: any) => {
  return getData(`${apiUrl.NOTIFICATION_LIST}${parseQueryParams(value)}`)
}

export const markNotifcationAsRead = (id: string) => {
  return patchData(`${apiUrl.NOTIFICATION_LIST}/${id}/mark_as_read`, {})
}
export const markNotificationAsUnread = (id: string) => {
  return patchData(`${apiUrl.NOTIFICATION_LIST}/${id}/mark_as_unread`, {})
}
export const clearAllNotification = () => {
  return postData(`${apiUrl.NOTIFICATION_CLEAR}`, {})
}
export const deleteNotification = (ids: any) => {
  return postData(`${apiUrl.NOTIFICATION_LIST}/delete`, ids)
}
export const markAllNotificationasRead = () => {
  return postData(`${apiUrl.NOTIFICATION_LIST}/mark_all_as_read`, {})
}
