import { MODULES } from '../../../configs/scopes.config'
import { getNestedProperty } from '../../../utilities/parsers'
import Icons from '../../common/icons/index'

const defaultColumnProps1 = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
export const getColumns = (
  { onViewAction }: any,
  modal?: string,
  chekModulePermission?: (key: string, module: string) => boolean
) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'custom':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {getNestedProperty(row, key[0]) && (
                  <Icons
                    name="phone"
                    toolTip={getNestedProperty(row, key[0])}
                  />
                )}
                {getNestedProperty(row, key[1]) && (
                  <Icons
                    name="email"
                    toolTip={getNestedProperty(row, key[1])}
                  />
                )}
              </div>
            ),
          }
        case 'multiple':
          return {
            cell: (
              <>
                {modal === 'OrderItemTaskEmployee' ? (
                  <>
                    <span>{row?.service_service_name}</span>
                  </>
                ) : (
                  <>
                    {row?.lead_service_name
                      ?.slice(0, 1)
                      .map((item: any, index: number) => (
                        <span key={item.id}>
                          {item}
                          {row?.lead_service_name.length - 1 !== index && ', '}
                        </span>
                      ))}
                    {row?.lead_service_name?.length > 1 && (
                      <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                        +{row.lead_service_name.length - 1}
                      </span>
                    )}
                  </>
                )}
              </>
            ),
          }

        case 'fileType':
          return {
            cell: (
              <>
                {row?.collectable ? (
                  <span key={row?.collectable.id}>
                    {`Collectable : ${row?.collectable?.display_name}`}
                  </span>
                ) : row?.deliverable ? (
                  <span key={row?.deliverable?.id}>
                    {`Deliverable : ${row?.deliverable?.display_name}`}
                  </span>
                ) : (
                  ''
                )}
              </>
            ),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
        case 'linksTo':
          return {
            cell: <> {`${row?.links_to?.type}: ${row?.links_to?.label}`} </>,
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'File Name',
      field: 'attachment_name',
      link: chekModulePermission?.('download', MODULES.attachment)
        ? true
        : false,
      getPath: (row: any) =>
        chekModulePermission?.('download', MODULES.attachment)
          ? row?.attachment || '#'
          : '#',
      ...defaultColumnProps1,
    },
    {
      title: 'File Type',
      field: 'file_classification_label',
      renderCell: createRenderCell('document_type.name', 'fileType'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'File Classification',
      field: 'file_classification_label',
      ...defaultColumnProps1,
    },
    {
      title: 'Links to',
      field: 'label',
      renderCell: createRenderCell('links_to.label', 'linksTo'),
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'linksTo'),
      ...defaultColumnProps1,
    },
    {
      title: 'File Uploaded By',
      field: 'created_by.name',
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'File Uploaded On',
      field: 'datetime_created',
      type: 'date',
      customCell: true,
      ...defaultColumnProps1,
    },
    // {
    //   title: 'Service Name',
    //   field: '',
    //   renderCell: createRenderCell('lead_service_name', 'multiple'),
    //   customCell: true,
    //   ...defaultColumnProps1,
    // },
    // {
    //   title: 'Activity Name',
    //   field: 'service_activity_name',
    //   // renderCell: createRenderCell('service_activity_name', 'multiple'),
    //   ...defaultColumnProps1,
    // },
  ]
  return column
}
