import React from 'react'

import { TextFieldProps } from '../../../common/types'

const TextField: React.FC<TextFieldProps> = ({
  name,
  id,
  label,
  type = 'text',
  disabled = false,
  fullwidth = true,
  placeholder,
  adorement,
  register,
  edited = false,
  fieldEdit = false,
  required = false,
  autoComplete = false,
  autoFocus = false,
  onChange,
  errors,
  uppercase,
  value,
  ref,
  onBlur,
  hidden,
  actionLabel,
  handleAction,
  disableAction,
  handleDisableAction,
}) => {
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }

  const generateClassName = (from: string) => {
    let className = ''
    switch (from) {
      case 'input':
        className = ` w-full input ${uppercase ? 'uppercase placeholder:normal-case' : ''} ${
          fieldEdit || adorement ? 'pr-[75px] ' : 'pr-input '
        }`
        if (errors && errors[name]) {
          className += 'textfield textfield-error'
        } else {
          if (edited) {
            className += ' textfield-success '
          } else {
            if (fieldEdit) {
              className += ' textfield-editable'
            } else {
              className += ' textfield'
            }
          }
        }
        break
      case 'adorement':
        className += '  absolute right-0 adorement mr-[1px]'
        break
      default:
        break
    }
    return className
  }

  return (
    <div
      className={` ${fullwidth ? 'w-full' : 'w-auto'} ${
        hidden ? 'hidden' : ''
      }`}
    >
      {label && (
        <div className="flex justify-between items-center">
          <label className={`labels label-text`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
          {actionLabel && (
            <button
              className={'action_label cursor-pointer'}
              onClick={handleAction}
            >
              {' '}
              {actionLabel}{' '}
            </button>
          )}
          {disableAction && (
            <div className="toggle-switch">
              <input
                className="toggle-input"
                onChange={(e) => handleDisableAction?.(e.target.checked)}
                id={name}
                type="checkbox"
                checked={!disabled}
                name={name}
              />
              <label className="toggle-label" htmlFor={name}></label>
            </div>
          )}
        </div>
      )}
      <div className="relative flex items-center">
        <input
          id={id}
          disabled={disabled}
          {...register?.(name, { required })}
          value={value}
          onChange={onChange}
          ref={ref}
          placeholder={placeholder || label}
          onBlur={onBlur}
          type={type}
          autoComplete={autoComplete ? 'on' : 'off'}
          autoFocus={autoFocus}
          className={generateClassName('input')}
          hidden={hidden}
        />
      </div>
      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}
    </div>
  )
}

export default TextField
