import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  getUserList,
  getPriorityList,
  getTaskCategory,
  getTaskStatus,
  useCreateTask,
  useUpdateTask,
} from '../../../../apis/common.apis'
import { useAuthStore } from '../../../../store/authStore'
import CustomDrawer from '../../../common/drawer'
import FormFieldView from '../../../common/inputs/FormFieldView'
import FormBuilder from '../../formBuilder'
import { attachmentsSchema, AttachmentsSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  disabled?: boolean
  handleClose: () => void
  paramsId?: any
  handleCallback?: () => void
  model_name?: string
  rowData?: any
  isOwnTask?: boolean
  isGeneral?: boolean
  viewMode?: boolean
  setViewMode?: (value: boolean) => void
  edit?: boolean
  hasPermission?: boolean
  setEdit?: (value: boolean) => void
}

export default function CreateTask({
  isDrawerOpen,
  handleClose,
  paramsId,
  handleCallback,
  model_name,
  isGeneral,
  isOwnTask,
  rowData,
  viewMode,
  setViewMode,
  disabled = false,
  edit,
  setEdit,
  hasPermission,
}: Props) {
  const queryClient = useQueryClient()
  const onSuccess = () => {
    handleSubmission()
  }
  const { mutate, isLoading } = useCreateTask(onSuccess)
  const { mutate: updateMutation, isLoding: loading } = useUpdateTask(onSuccess)
  const vendor_id = useAuthStore?.getState()?.userData?.vendor || ''
  const handleClearAndClose = () => {
    handleReset()
    setEdit?.(false)
    setViewMode?.(false)
    handleClose()
  }

  const [pageCount, setPage] = useState(1)
  const { userData, isMember } = useAuthStore()

  const methods = useForm<AttachmentsSchema>({
    resolver: zodResolver(attachmentsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { due_date, assign_to_id } = methods.watch()

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const handleEventType = async () => {
    const { data } = await getTaskCategory()

    return data?.results
  }

  const handleTask = async () => {
    const { data } = await getTaskStatus()
    if (!viewMode && !edit && !assign_to_id) {
      setEmployeeAssignment(data?.results)
    }
    return data?.results
  }
  const handlePriority = async () => {
    const { data } = await getPriorityList()
    return data
  }
  const setEmployeeAssignment = async (taskResults: any) => {
    methods.setValue('assign_to_id', isMember ? (userData?.id as string) : '')
    methods.setValue(
      'assign_to_name',
      isMember ? (userData?.name as string) : ''
    )
    methods.reset({
      ...methods.watch(),
      status_id: taskResults[0]?.id ?? '',
      status_name: taskResults[0]?.name ?? '',
    })
  }
  const loadEmployeeList = async (value?: string, page?: number) => {
    const data = await getUserList({
      search: value ?? '',
      page: page as number,
      vendor_id: vendor_id,
      model_name: model_name ?? '',
      model_id: paramsId,
    })

    const pageLimit = data?.next ? pageCount + 1 : 0
    setPage(pageLimit)
    return data?.results?.map((item: any) => ({
      ...item,
      cre_agent_user_name: item.name,
    }))
  }
  useEffect(() => {
    if (isDrawerOpen) {
      if (!viewMode && !edit) {
        handleTask()
      } else {
        methods.reset({
          linked_entity:
            // rowData?.model_name && rowData?.model_item_name
            //   ? `${rowData?.model_name}: ${rowData?.model_item_name}`
            //   : '',
            rowData?.related_entity && rowData?.related_entity?.payment_number
              ? `Transaction No: ${rowData?.related_entity?.payment_number}`
              : '',
          task: rowData?.task ?? '',
          reminder_date: rowData?.reminder_date
            ? new Date(rowData?.reminder_date)
            : null,
          reminder_time: rowData?.reminder_time ?? null,
          category_id: rowData?.category?.id ?? '',
          assign_to_id: rowData?.assign_to?.id ?? '',
          status_id: rowData?.status?.id ?? '',
          status_name: rowData?.status?.name,
          assign_to_name: rowData?.assign_to?.name,
          due_date: rowData?.due_date ? new Date(rowData?.due_date) : null,
          category_Name: rowData?.category?.name,
          task_priority_name: rowData?.task_priority?.display_name,
          task_priority_id: rowData?.task_priority?.id,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, viewMode, edit, isDrawerOpen])
  const formBuilderProps = [
    ...(rowData?.model_name && rowData?.model_item_name
      ? [
          !viewMode
            ? {
                ...textField(
                  'linked_entity',
                  'Linked Entity',
                  'Enter Linked Entity',
                  true
                ),
                type: 'text',
                value:
                  rowData?.related_entity &&
                  rowData?.related_entity?.payment_number
                    ? `Transaction No: ${rowData?.related_entity?.transactions[0]?.transaction_id}`
                    : '',
                // rowData?.model_name && rowData?.model_item_name
                //   ? `${rowData?.model_name}: ${rowData?.model_item_name}`
                //   : '',
                required: false,
                disabled: true,
                // hidden: !viewMode && !edit,
                hidden: rowData?.category
                  ? rowData?.category?.code == 'payments_check'
                    ? false
                    : true
                  : !viewMode && !edit,
              }
            : {
                name: 'linked_entity',
                label: 'Linked Entity',
                async: false,
                value:
                  rowData?.related_entity &&
                  rowData?.related_entity?.payment_number
                    ? `Transaction No: ${rowData?.related_entity?.transactions[0]?.transaction_id}`
                    : '',
                required: false,
                placeholder: 'Enter Linked Entity',
                disabled: true,
                // hidden:
                //   rowData?.category?.code == 'payments_check' ? false : true,
                // hide: true,
                hidden: rowData?.category
                  ? rowData?.category?.code == 'payments_check'
                    ? false
                    : true
                  : !viewMode && !edit,
                link:
                  viewMode && rowData?.related_entity?.id
                    ? `${window.location.origin}/orders/${rowData?.model_id}/payments/${rowData?.related_entity?.id}`
                    : undefined,
              },
        ]
      : []),
    {
      name: 'category_Name',
      label: 'Task Category',
      getData: handleEventType,
      async: false,
      id: 'category_id',
      descId: 'id',
      initialLoad: true,
      value: rowData?.category?.name,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Task Category',
      disabled: !isOwnTask && edit,
    },
    {
      ...textField('task', 'Task Details ', 'Enter Task', true),
      type: 'textarea',
      value: rowData?.task,
      required: true,
      disabled: !isOwnTask && edit,
    },
    {
      ...textField('due_date', 'Due Date', 'Enter Due Date'),
      minDate: new Date(),
      type: 'date',
      value: rowData?.due_date
        ? moment(rowData?.due_date).format('DD-MM-YYYY')
        : null,

      required: true,
      disabled: !isOwnTask && edit,
    },
    {
      ...textField(
        'reminder_date',
        'Reminder Date',
        'Enter Reminder Date',
        true
      ),
      minDate: new Date(),
      maxDate: due_date,
      type: 'date',
      value: rowData?.reminder_date
        ? moment(rowData?.reminder_date).format('DD-MM-YYYY')
        : null,
      disabled: !due_date || (!isOwnTask && edit),
    },
    {
      ...textField(
        'reminder_time',
        'Reminder Time',
        'Enter Reminder Time',
        true
      ),
      minDate: new Date(),
      type: 'time_only',
      value: rowData?.reminder_time ?? null,
      disabled: !isOwnTask && edit,
    },

    {
      name: 'assign_to_name',
      label: 'Task Assignee',
      getData: loadEmployeeList,

      async: true,
      id: 'assign_to_id',
      descId: 'id',
      value: rowData?.assign_to?.name,

      nextBlock: pageCount,
      paginationEnabled: true,
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Task Assignee',
      disabled: !isOwnTask && edit,
    },
    {
      name: 'status_name',
      label: 'Task Status',
      getData: handleTask,
      async: false,

      id: 'status_id',
      descId: 'id',
      initialLoad: true,
      value: rowData?.status?.name,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Task Status',
    },
    ...(methods.watch().status_id &&
    methods.watch().status_id != rowData?.status?.id &&
    edit
      ? [
          {
            ...textField('remark', 'Task Remark ', 'Enter Remark', true),
            type: 'textarea',
            value: '',
            required: false,
          },
        ]
      : []),
    {
      name: 'task_priority_name',
      label: 'Priority',
      getData: handlePriority,
      async: false,

      id: 'task_priority_id',
      descId: 'id',
      initialLoad: true,
      value: rowData?.task_priority?.display_name,
      required: true,
      disabled: !isOwnTask && edit,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Priority',
    },
  ]

  const handleSubmission = () => {
    if (isGeneral) {
      queryClient.invalidateQueries({
        queryKey: ['my-tasks'],
      })
    } else {
      console.log('not isisgeneal')
    }
    handleCallback?.()
    methods.reset()
    handleClearAndClose()
  }
  const handleReset = () => {
    methods.reset({
      task: '',
      reminder_date: null,
      reminder_time: null,
      category_id: '',
      assign_to_id: '',
      status_id: '',
      status_name: '',
      assign_to_name: '',
      due_date: null,
      category_Name: '',
      task_priority_id: '',
      task_priority_name: '',
    })
  }

  const onSubmit = (data: any) => {
    const result = {
      ...data,
      model_name: model_name,
      model_id: paramsId,
      due_date: data.due_date
        ? moment(data.due_date).format('YYYY-MM-DD')
        : null,
      reminder_date: data.reminder_date
        ? moment(data.reminder_date).format('YYYY-MM-DD')
        : null,
      // status_id: 'e930ea59-8457-4309-96ea-95e5515807a6',
      status_name: undefined,
      assign_to_name: undefined,
      category_Name: undefined,
      task_priority_name: undefined,
    }
    if (rowData?.id) {
      updateMutation({ id: rowData?.id, data: result })
    } else {
      mutate(result)
    }
  }
  const { handleSubmit } = methods

  const handleChangeMode = () => {
    setViewMode?.(false)
    setEdit?.(true)
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      actionLabel={viewMode ? 'Edit' : 'Save'}
      handleClose={() => handleClearAndClose()}
      hideSubmit={viewMode ? !hasPermission || disabled : false}
      actionLoader={isLoading || loading}
      handleSubmit={
        viewMode ? handleChangeMode : handleSubmit((data) => onSubmit(data))
      }
      title={edit ? 'Edit Task' : viewMode ? 'View Task' : 'Create Task'}
    >
      <div className="flex flex-col gap-4">
        {!viewMode ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        ) : (
          formBuilderProps.map((data) => (
            <FormFieldView
              value={data.value}
              label={data.label}
              key={data?.id}
              type={data?.type}
              link={data?.link}
              hidden={data.hidden}
            />
          ))
        )}
      </div>
    </CustomDrawer>
  )
}
