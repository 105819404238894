import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import {
  getErrorMessage,
  getNestedProperty,
} from '../../../../utilities/parsers'
import {
  deleteStakeholderFromTeam,
  getStakeholderDetailsTeams,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import AddTeam from './addTeam'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
export const getColumns = () => {
  const createRenderCell = (key: string) => (row: any) => {
    return {
      cell: getNestedProperty(row, key),
      toolTip: getNestedProperty(row, key),
    }
  }

  return [
    {
      title: 'Team',
      field: 'name',
      ...defaultColumnProps,
      customCell: true,
      link: true,
      getPath: (row: any) =>
        `${router_config.SYSTEM_SETTINGS_ORGANIZATION_TEAMS.path}/${row?.vendor_team?.id}/info`,
      renderCell: createRenderCell('vendor_team.name'),
    },
  ]
}
const MembersList = () => {
  const params = useParams()

  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  const [isTeamOpen, setIsTeamOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [pageParams, setPageParams] = useState<any>({
    page: 1,
    page_size: 30,
    search: '',
    ordering: '',
  })
  const { page, page_size, search, ordering } = pageParams

  const fetch = () => {
    getStakeholderDetailsTeams(params.id, {
      ...pageParams,
    })
      .then((res: any) => {
        setData(res)
      })
      .catch((err: any) => {
        getErrorMessage(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, page_size, search, ordering])
  const [deleteOpen, setDeleteOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbarManager()

  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }

  const handleDelete = () => {
    deleteStakeholderFromTeam(params.id, activeRow.id)
      .then(() => {
        enqueueSnackbar('Team deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        handleRefresh()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }
  const handleRefresh = () => {
    fetch?.()
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleAction = () => {
    setIsTeamOpen(true)
  }
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.results || []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={loading}
          columns={getColumns()}
          // handleResetColumns={() => handleResetColums()}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Add New'}
                hidden={!checkDetailPermission('add', 'team')}
                className="se"
                outlined
              />
            </div>
          }
          actionProps={[
            {
              icon: <Icons name="delete" />,
              action: (row) => handleDeleteOpen(row),
              title: 'delete',
              toolTip: 'Delete',
              hidden: !checkDetailPermission('delete', 'team'),
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          handleSearchValue={(key?: string) => handleSeach(key)}
          asyncSearch
          columnToggle
        />
        <AddTeam
          isDrawerOpen={isTeamOpen}
          paramsId={params?.id}
          handleClose={() => setIsTeamOpen(false)}
          handleCallback={handleRefresh}
        />
        <DialogModal
          isOpen={deleteOpen}
          onClose={() => handleDeleteClose()}
          title={'Are you sure?'}
          onSubmit={() => handleDelete()}
          secondaryAction={() => handleDeleteClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={<InfoBox content={'Are you sure you want to delete?'} />}
        />
      </div>
    </>
  )
}

export default MembersList
