import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

const defaultParams = [
  { title: 'Next Followup Date', slug: 'followup_date', isChecked: false },
  { title: 'Language Pref', slug: 'language_pref', isChecked: false },
  { title: 'Lead Source', slug: 'lead_source', isChecked: false },
  { title: 'Lead Quality', slug: 'lead_quality_id', isChecked: false },
  { title: 'Sector', slug: 'sector', isChecked: false },
  { title: 'Industry', slug: 'industry', isChecked: false },
  { title: 'Sub sector', slug: 'sub_sector', isChecked: false },
  { title: 'Service Name', slug: 'service_name', isChecked: false },
  { title: 'Country', slug: 'country', isChecked: false },
  { title: 'State', slug: 'state', isChecked: false },
  { title: 'Created By', slug: 'crb', isChecked: false },
  { title: 'Updated By', slug: 'upb', isChecked: false },
  { title: 'Team', slug: 'team', isChecked: false },
]
export const useLeadFilterStore = create<LeadFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearLeadFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__lead_store' }
  )
)

type LeadFilterstoreType = {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]

  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearLeadFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
