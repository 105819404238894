import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { switchPath } from '../../../layout/switchPath'
import { useAppStore } from '../../../store/appStore'
import { useAuthStore } from '../../../store/authStore'

import Icons from '../../common/icons/index'

const WebSocketComponent: React.FC = () => {
  const [notificationData, setNotificationData] = useState<any>()
  const { openNotification, setOpenNotification } = useAppStore()

  useEffect(() => {
    const token = useAuthStore.getState().token
    const loc = process.env.REACT_APP_WEBSOCKET_URL

    const webSocketEndpoint = `${loc}?Token=${token}`

    const client = new WebSocket(webSocketEndpoint)

    client.onopen = () => {
      console.log('WebSocket Client Connected')
      const pingInterval = setInterval(() => {
        client.send(JSON.stringify({ type: 'ping' }))
      }, 300000) // 300000 milliseconds = 5 minutes
      client.onclose = () => {
        clearInterval(pingInterval)
      }
    }

    client.onmessage = (message) => {
      setNotificationData(JSON.parse(message.data))
      setOpenNotification(true)
    }

    client.onerror = function (e) {
      console.log('Connection Error', e)
    }

    client.onclose = function () {
      setOpenNotification(false)
    }
    return () => {
      client.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAuthStore.getState().token])

  const handleNavigate = () => {
    if (notificationData) {
      const path = switchPath(notificationData?.data)
      if (path !== null) {
        navigate(`${path}`)
      }
    }
  }
  const handleOpenEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (notificationData.data) {
      handleNavigate()
    }
    e.stopPropagation()
  }
  const navigate = useNavigate()

  return (
    <>
      {openNotification && (
        <>
          <div
            className=" min-w-[400px] fixed top-5 right-5 flex flex-col items-center bg-white shadow-md border-2 rounded-sm   px-4 py-3 z-1300"
            role="alert"
          >
            <div className="flex justify-between gap-5 items-center w-full">
              <p className=" text-lg font-medium">
                {notificationData?.title ?? 'New notification'}
              </p>
              <span
                className=" cursor-pointer"
                onClick={() => setOpenNotification(false)}
              >
                <Icons name="close" className=" text-sm" />
              </span>
            </div>
            <div
              onClick={(e) => handleOpenEvent(e)}
              className={`flex w-full py-2 items-start gap-2 ${
                notificationData?.title.includes('Deleted')
                  ? ''
                  : 'cursor-pointer'
              } `}
            >
              <span className=" flex bg-primary text-white border items-start rounded-full p-1 ">
                <Icons name="notify-icon-big" className="" />
              </span>
              <div>
                <p className=" text-sm max-w-[400px]  line-clamp-3">
                  {notificationData?.content}
                </p>
                <p className=" text-xs text-grey-medium">a few seconds ago</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default WebSocketComponent
