import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { postData } from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { useSnackbarManager } from '../../components/common/snackbar'
import { checkPermissionAny } from '../../configs/permissionGate'
import { MODULES } from '../../configs/scopes.config'
import { useAppStore } from '../../store/appStore'
import { useAuthStore } from '../../store/authStore'
import { useClearFilter } from '../../store/filterSore/clearStore'
import { LoginSchema } from './schema'

export const useLogin = (handleOnSuccess: any) => {
  const {
    setToken,
    setAuthenticated,
    setPermissionData,
    setRoleData,
    setIsMember,
    setUserData,
    setFranchisee,
    setFranchiseeDefaultGateway,
  } = useAuthStore()
  const { setResetToken, setPasswordExpired } = useAppStore()

  const handleClear = useClearFilter()
  const { setIsLoading } = useAppStore()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbarManager()

  const loginMutation = useMutation(
    async (params: LoginSchema) => {
      setIsLoading(true)
      const data = {
        password: params.password,
        username: params.username.toLowerCase(),
      }
      const response = await postData(apiUrl.LOGIN_URL, data)

      return response
    },
    {
      onError: (err: any) => {
        enqueueSnackbar(
          err?.response?.data?.error?.message ??
            err?.response?.data?.error ??
            'Login failed',
          {
            variant: 'error',
          }
        )
      },
      onSuccess: (data: any) => {
        localStorage.setItem('shouldReload', 'false')
        const res = data.data
        setPasswordExpired(res.is_password_expired)
        setResetToken(res.reset_password_token)
        if (!res.is_password_expired) {
          setToken(res.token)

          setAuthenticated(true)
          handleOnSuccess?.()
          handleClear()
          setUserData({
            id: res.user_data?.id,
            name: res.user_data?.name,
            is_admin: res.user_data?.is_admin,
            is_team_lead: res.user_data?.is_team_lead,
            is_operations_head: res.user_data?.is_operations_head,
            email: res.user_data?.user?.email,
            email_2: res.user_data?.user?.email_2,
            first_name: res.user_data?.user?.first_name,
            last_name: res.user_data?.user?.last_name,
            mobile: res.user_data?.user?.mobile,
            mobile_2: res.user_data?.user?.mobile_2,
            username: res.user_data?.user?.username,
            vendor: res.user_data?.vendor,
          })
          setFranchiseeDefaultGateway({
            ...res?.user_data?.franchisee?.default_payment_gateway,
          })
          setFranchisee({
            ...res?.user_data?.franchisee,
            is_default_franchisee: true,
          })
          setIsMember(true)
          setRoleData({
            name: res.user_data?.role?.name,
            id: res.user_data?.role?.id,
          })
          setPermissionData(res.user_data?.role?.permissions)
          localStorage.setItem('shouldReload', 'true')
          const path = checkPermissionAny(MODULES.dashboard)
            ? '/dashboard'
            : '/my-tasks'
          navigate(path)
        } else {
          navigate('/change-password')
        }

        // queryClient.invalidateQueries(['list']) refresh api call
      },

      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  return loginMutation
}

export const resetPassword = (data: any) => {
  return postData(`employee/forgot-password-request-email/`, data)
}
export const ForgetResetPassword = (data: any) => {
  return postData(`employee/forgot-password-reset/`, data)
}
export const verifyResetPassword = (data: any) => {
  return postData(`employee/forgot-password-verify-token`, data)
}
export const forceChangePassword = (data: any) => {
  return postData(`employee/force-change-password/`, data)
}
