import { MODULES } from '../../../../configs/scopes.config'
import { checkPermissionAny } from '../../../../configs/permissionGate'

const module = MODULES.paymentout
export const checkPermission = (slug: string) => {
  const permissionSlug = `${slug}_${module}`
  return checkPermissionAny(permissionSlug)
}
export const checkDetailPermission = (slug: string, subModule?: string) => {
  const permissionSlug = `${slug}_${module}_${subModule}`
  return checkPermissionAny(permissionSlug)
}
