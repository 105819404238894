import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { deletePayout, useGovtFeeLists } from '../../api'
import { statusClassGen } from '../../../../utilities/generators'
import { getColumns } from './paymentRequestColumns'

import { Button, DialogModal } from '../../../../components/common'
import AddPayout from './addPayout'
import Icons from '../../../../components/common/icons'
import { checkPermission } from './permission'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../utilities/parsers'
import InfoBox from '../../../../components/app/alertBox/infoBox'

const ServiceGovtFees = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
  }>()
  const [open, setOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [viewMode, setViewMode] = useState(false)
  const [edit, setEdit] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const params = useParams()
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching, refetch } = useGovtFeeLists({
    // approval_type__requires_payment: true,
    id: params?.id ?? '',
  })
  const handleCloseAll = () => {
    setOpen(false)
    setViewMode(false)
    setActiveRow(undefined)
    setEdit(false)
  }
  const handleViewPayment = (row: any) => {
    setActiveRow(row)
    setViewMode(true)
    setOpen(true)
  }
  const handleEdit = (r: any) => {
    setEdit(true)
    setOpen(true)
    setActiveRow(r)
    setViewMode(false)
  }

  const handleAddFee = () => {
    setViewMode(false)
    setOpen(true)
  }

  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDelete = () => {
    deletePayout(activeRow?.id)
      .then(() => {
        enqueueSnackbar('Deleted successfully', {
          variant: 'success',
        })
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error.message ??
              error?.response?.data?.errors.message
          ),
          {
            variant: 'error',
          }
        )
      })
    handleDeleteClose()
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                label={'Add New'}
                // disabled={disabled}
                hidden={!checkPermission('add')}
                icon={'plus'}
                onClick={() => handleAddFee()}
              />
            </div>
          }
          columns={
            getColumns({
              onViewAction: handleViewPayment,
              statusClassGen: statusClassGen,
            }) as any
          }
          actionProps={[
            {
              icon: <Icons name="eye" />,
              action: (row) => handleViewPayment(row),
              title: 'view-payment',
              hidden: !checkPermission('view'),
              toolTip: 'View',
            },
            {
              icon: <Icons name="edit" />,
              action: (row) => handleEdit(row),
              title: 'view-payment',
              hidden: !checkPermission('change'),
              toolTip: 'Edit',
            },
            {
              title: 'Delete Payout',
              action: (row: any) => {
                handleDeleteOpen(row)
              },
              icon: <Icons name="delete" />,
              hidden: !checkPermission('delete'),
              toolTip: 'Delete Payout',
            },
          ]}
          columnToggle
        />
        <AddPayout
          isDrawerOpen={open}
          subSection={true}
          data={activeRow}
          handleClose={() => handleCloseAll()}
          handleCallback={refetch}
          id={params.id}
          setViewMode={setViewMode}
          viewMode={viewMode}
          edit={edit}
        />
        <DialogModal
          isOpen={deleteOpen}
          onClose={() => handleDeleteClose()}
          title={'Are you sure?'}
          onSubmit={() => handleDelete()}
          secondaryAction={() => handleDeleteClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={<InfoBox content={'Are you sure you want to delete?'} />}
        />
      </div>
    </>
  )
}

export default ServiceGovtFees
