import { useQuery } from '@tanstack/react-query'

import { deleteData, getData, postData } from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
const fetchData = async (input: QueryParams, status_code?: string) => {
  console.log(status_code)
  const url = buildUrlWithParams(apiUrl.PAYMENT_LIST, {
    ...input,
    ...(status_code ? { status_code } : {}),
  })
  const response = await getData(url)
  return response?.data
}

export const usePayments = ({
  input,
  status_code,
}: {
  input: QueryParams
  status_code?: string
}) => {
  return useQuery(['payments', input], () => fetchData(input, status_code), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const deletePayment = async (id?: string) => {
  return deleteData(`${apiUrl.PAYMENT_LIST}${id}`)
}
export const verifiyPayments = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.PAYMENT_LIST}${data.id}/verify-payment`, data.input)
}
export const getPaymentVerificationStatus = async (params: Params) => {
  return getData(
    buildUrlWithParams(`${apiUrl.PAYMENT_VERIFICATION_STATUS}`, params)
  )
}
export const getOrderStatusList = async (params: Params) => {
  return getData(buildUrlWithParams(apiUrl.ORDER_STATUS, params))
}

export const { getPaymentStatusList, getPaymentTypesList, getAccountList } = {
  getPaymentStatusList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.PAYMENT_STATUS, params)),

  getPaymentTypesList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.PAYMENT_TYPES, params)),
  getAccountList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.ACCOUNT_LIST_URL, params)),
}
