import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import {
  allowActionsByStatusExcept,
  blockActionsbyStatus,
} from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { getErrorMessage, numberFormat } from '../../../../utilities/parsers'
import AddService from '../../addServices'
import AllocatePayments from '../../allocatePayments'
import {
  cancelService,
  restoreService,
  useOrderServiceDetails,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import AssignTeam from './assignTeam'
import { getColumns } from './servicesColumns'

const ContactAccounts = () => {
  const {
    setActiveTab,
    handleCallBack: handleParentCallBack,
    detailsData,
  } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    detailsData: any
  }>()
  const { enqueueSnackbar } = useSnackbarManager()
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const [isAllocatePaymentOpen, setIsAllocatePaymentOpen] = useState(false)
  const [allocateData, setAllocateData] = useState<any>({})
  const [openAssignTeam, setOpenAssign] = useState(false)
  // const [deleteOpen, setDeleteOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [restoreOpen, setRestoreOpen] = useState(false)

  const [rowData, setRowData] = useState<any>({})
  const [detailData, setDetailData] = useState<any>({})
  const params = useParams()

  const availableAllocation = numberFormat(
    detailsData?.paid_amount - detailsData?.allocated_amount
  )

  // const handleAction = () => {
  //   setIsAccountOpen(true)
  // }

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_SERVICES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setDetailData(detailsData)
  }, [detailsData])
  const handleAllocatePayment = (data: any) => {
    setIsAllocatePaymentOpen(true)
    setAllocateData(data)
  }
  const handleAssignTeam = (data: any) => {
    setOpenAssign(true)
    setAllocateData(data)
  }
  // const handleDeleteClose = () => {
  //   setDeleteOpen(false)
  // }
  // const handleDeleteOpen = (row: any) => {
  //   setDeleteOpen(true)
  //   setRowData(row)
  // }
  const handleCallback = () => {
    refetch()
    handleParentCallBack?.()
  }
  // const handleDeleteService = () => {
  //   deleteOrderUnallocatedService(rowData?.order?.id, rowData?.id)
  //     .then(() => {
  //       enqueueSnackbar('Service cancelled successfully', {
  //         variant: 'success',
  //       })
  //       handleDeleteClose()
  //       handleCallback()
  //     })
  //     .catch((error: any) => {
  //       enqueueSnackbar(
  //         getErrorMessage(
  //           error?.response?.data?.errors[0]?.message[0] ??
  //             error?.response?.data?.errors
  //         ),
  //         {
  //           variant: 'error',
  //         }
  //       )
  //     })
  // }
  const handleCancel = () => {
    // deleteService
    cancelService(rowData.id)
      .then(() => {
        enqueueSnackbar('Service cancelled successfully', {
          variant: 'success',
        })
        handleCancelClose()
        handleCallback()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }
  const handleRestore = () => {
    restoreService(rowData.id)
      .then(() => {
        enqueueSnackbar('Service restored successfully', {
          variant: 'success',
        })
        handleCancelClose()
        handleCallback()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleCancelClose = () => {
    setCancelOpen(false)
    setRestoreOpen(false)
    setRowData(null)
  }
  const handleCancelOpen = (row: any) => {
    setRowData(row)
    setCancelOpen(true)
  }
  const handleRestoreOpen = (row: any) => {
    setRowData(row)
    setRestoreOpen(true)
  }
  const { data, isFetching, refetch } = useOrderServiceDetails(
    params?.id ?? '',
    {}
  )
  const onViewAction = (r: any) => {
    return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${r?.id}/summary`
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          // selection={true}
          search={true}
          isLoading={isFetching}
          columns={
            getColumns({
              onViewAction: onViewAction,
            }) as any
          }
          // tableHeaderActions={
          //   <div className="flex gap-2">
          //     <Button
          //       label={'Add New'}
          //       icon={'plus'}
          //       hidden={!checkDetailPermission('add', MODULES.orderService)}
          //       onClick={handleAction}
          //       disabled={
          //         (blockActionsbyStatus(detailData?.order_status?.code, [
          //           'cancelled',
          //           'order_cancelled',
          //           'rejected',
          //           'order_completed_payment_done',
          //         ]) &&
          //           detailData?.is_locked) ||
          //         detailData?.has_active_invoice
          //       }
          //     />
          //   </div>
          // }
          actionProps={[
            ...(allowActionsByStatusExcept(detailData?.order_status?.code)
              ? [
                  {
                    icon: <Icons name="allocate-payment" />,
                    action: (row: any) => handleAllocatePayment(row),
                    title: 'allocate-payment',
                    hidden:
                      true ||
                      !checkDetailPermission(
                        'allocate',
                        MODULES.orderService
                      ) ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'order_completed_payment_done',
                      ]) &&
                        detailData?.is_locked),
                    hide: (row: any) =>
                      row?.service_status?.status?.category?.code ===
                      'cancelled'
                        ? true
                        : false || true,
                    toolTip: 'Allocate payment',
                  },
                  {
                    icon: <Icons name="assign-team" />,
                    action: (row: any) => handleAssignTeam(row),
                    hidden:
                      !checkDetailPermission(
                        'assignteam',
                        MODULES.orderService
                      ) ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'order_completed_payment_done',
                      ]) &&
                        detailData?.is_locked),
                    hide: (row: any) =>
                      row?.service_status?.status?.category?.code ===
                      'cancelled'
                        ? true
                        : false,

                    toolTip: 'Assign team',
                  },

                  // icon: <Icons name="delete" />,
                  // action: (row: any) => handleDeleteOpen(row),
                  // title: 'delete',
                  // toolTip: 'Delete',
                  // hidden: !checkDetailPermission(
                  //   'delete',
                  //   MODULES.orderService
                  // ),
                  // {
                  //   icon: <Icons name="delete" />,
                  //   action: (row: any) => handleDeleteOpen(row),
                  //   title: 'delete',
                  //   toolTip: 'Delete',

                  //   hide: (row: any) =>
                  //     row?.service_allocated_amount === null ? false : true,
                  // },

                  {
                    title: 'Cancel',
                    action: (row: any) => handleCancelOpen(row),
                    icon: <Icons name="cancel" />,
                    // || row?.service_allocated_amount !== null
                    hide: (row: any) =>
                      row?.service_status?.status?.category?.code ===
                      'cancelled'
                        ? true
                        : false,
                    hidden:
                      !checkDetailPermission('delete', MODULES.orderService) ||
                      detailData.has_active_invoice ||
                      (blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'order_completed_payment_done',
                      ]) &&
                        detailData?.is_locked) ||
                      true,
                    toolTip: 'Cancel',
                    // hidden: !checkPermissionAny(SCOPES.view_customer),
                  },
                  {
                    title: 'Restore',
                    action: (row: any) => handleRestoreOpen(row),
                    icon: <Icons name="restore" />,
                    hide: (row: any) =>
                      row?.service_status?.status?.category?.code !==
                      'cancelled'
                        ? true
                        : false,
                    toolTip: 'Restore',
                    hidden:
                      !checkDetailPermission('restore', MODULES.orderService) ||
                      true,
                    // hidden: !checkPermissionAny(SCOPES.view_customer),
                  },
                ]
              : []),
          ]}
          columnToggle
        />
      </div>
      <AddService
        isDrawerOpen={isAccountOpen}
        subSection={true}
        handleClose={() => setIsAccountOpen(false)}
        handleCallback={handleCallback}
        id={params.id}
      />
      <AllocatePayments
        isDrawerOpen={isAllocatePaymentOpen}
        subSection={true}
        handleClose={() => setIsAllocatePaymentOpen(false)}
        handleCallback={handleCallback}
        id={params.id}
        datas={allocateData}
        availableAllocation={availableAllocation}
      />
      <AssignTeam
        isDrawerOpen={openAssignTeam}
        orderId={params?.id as string}
        serviceId={allocateData?.id}
        handleCallback={handleCallback}
        handleClose={() => setOpenAssign(false)}
      />
      {/* <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDeleteService()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to cancel this service? This process is irreversible'
            }
          />
        }
/> */}
      <DialogModal
        isOpen={cancelOpen}
        onClose={() => handleCancelClose()}
        title={'Are you sure?'}
        onSubmit={() => handleCancel()}
        secondaryAction={() => handleCancelClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={' Are you sure you want to proceed with the cancellation?'}
          />
        }
      />
      <DialogModal
        isOpen={restoreOpen}
        onClose={() => handleCancelClose()}
        title={'Are you sure?'}
        onSubmit={() => handleRestore()}
        secondaryAction={() => handleCancelClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={' Are you sure you want to proceed with the restoration?'}
          />
        }
      />
    </>
  )
}

export default ContactAccounts
