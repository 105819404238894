import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../components/app/formBuilder'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import FormLinkView from '../../../../components/common/inputs/FormLink'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../utilities/parsers'
import { updateTargetCloseDate } from '../../api'
import { serviceDetailsSchema, ServiceDetailsSchema } from './schema'

type Props = {
  data?: any
  serviceId?: string
  handleRefresh: () => void
  hasPermission?: boolean
  disabled?: boolean
}
const BasicInformation = (props: Props) => {
  const { data, serviceId, handleRefresh, hasPermission, disabled } = props
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string,
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
    ...(disabled ? { disabled: true } : {}),
  })
  const onInit = () => {
    return {
      order_date: data?.order_date,
      unique_id: data?.unique_id,
      assigned_vendor_team_lead: data?.assigned_vendor_team_lead,
      target_close_date: data?.target_close_date ?? null,
      end_date: data?.end_date
        ? moment(data?.end_date).format('DD-MM-YYYY')
        : '',
      assigned_date: data?.assigned_date
        ? moment(data?.assigned_date).format('DD-MM-YYYY')
        : '',
      order_service_status: data?.service_status?.status?.name,
      service_progress_percentage: data?.service_progress_percentage,
      brand_name: data?.brand_name,
      class_number: data?.class_number,
      additional_status: data?.additional_status?.display_name,
    }
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formBuilderProps = [
    textField(
      'unique_id',
      'Service ID',
      'Enter Service ID',
      false,
      data?.unique_id,
      true
    ),
    textField(
      'assigned_vendor_team_lead',
      'Team Lead Name',
      'Team Lead Name',
      false,
      data?.assigned_vendor_team_lead,
      true
    ),
    textField(
      'assigned_date',
      'Start Date',
      'Start Date',
      false,
      data?.assigned_date
        ? moment(data?.assigned_date).format('DD-MM-YYYY')
        : '--',
      true
    ),
    {
      ...textField(
        'target_close_date',
        'Target Close Date',
        'Target Close Date',
        false,
        data?.target_close_date != null
          ? moment(data?.target_close_date).format('DD-MM-YYYY')
          : '--'
      ),
      minDate: new Date(),
      type: 'date',
      required: false,
    },
    textField(
      'order_service_status',
      'Service Status',
      'Service Status',
      false,
      data?.service_status?.status?.name,
      true
    ),

    textField(
      'service_progress_percentage',
      'Service Progress Percentage',
      'Enter Service Progress Percentage',
      false,
      data?.service_progress_percentage,
      true
    ),
    textField(
      'end_date',
      'Service End Date',
      'Service End Date',
      false,
      data?.end_date ? moment(data?.end_date).format('DD-MM-YYYY') : '',
      true
    ),
    ...(data?.is_trademark_service
      ? [
          textField(
            'brand_name',
            'Brand Name',
            'Brand Name',
            false,
            data?.brand_name ?? '',
            false
          ),
          textField(
            'additional_status',
            'Additional Status',
            'Additional Status',
            false,
            data?.additional_status?.display_name,
            true
          ),
          {
            ...textField(
              'class_number',
              'Class Number',
              'Class Number',
              false,
              data?.class_number ?? '',
              false
            ),
            type: 'number',
          },
        ]
      : []),
  ]
  const methods = useForm<ServiceDetailsSchema>({
    resolver: zodResolver(serviceDetailsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
  }

  const { handleSubmit } = methods
  const { enqueueSnackbar } = useSnackbarManager()

  const onSubmit = (dataItems: ServiceDetailsSchema) => {
    const targetDate =
      dataItems?.target_close_date && dataItems?.target_close_date !== ''
        ? moment(dataItems?.target_close_date).format('YYYY-MM-DD')
        : null
    updateTargetCloseDate(
      {
        target_close_date: targetDate,
        brand_name: dataItems.brand_name,
        class_number: dataItems.class_number,
      },
      data?.order?.id,
      serviceId
    )
      .then(() => {
        enqueueSnackbar('Updated successfully', {
          variant: 'success',
        })
        handleCancel()
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
      })
  }
  const linkDatas = [
    ...(data?.vendor_order?.id
      ? [
          {
            label: 'Connected Order',
            value: data?.vendor_order?.order_number,
            id: data?.vendor_order?.id,
            slug: 'order',
          },
        ]
      : []),

    // { label: 'Connected Order', value: data?.order?.name, id: data?.lead?.id,slug:'order' },
    // { label: 'Connected Deal', value: data?.deal?.name, id: data?.deal?.id,slug:'deal' },
  ]
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        ) : (
          formBuilderProps.map((data) => (
            <FormFieldView
              value={data.value}
              type={data?.type}
              label={data.label}
              key={data?.id}
            />
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        {hasPermission && (
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    outlined={false}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : (
              <>
                {!disabled && <Icons onClick={handleEditClick} name="edit" />}
              </>
            )}
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        {renderFields()}
        <FormLinkView data={linkDatas} />
      </div>
    </div>
  )
}

export default BasicInformation
