import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getAccounts } from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useMasterData } from '../../../../store/masterDataStore'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import CreateAccount from '../../../accounts/create'
import { checkPermission as checkAccountPermission } from '../../../accounts/permission'
import { useEditContact } from '../../api'
import { getContactDuplicateData } from '../../common/commonUtils'
import {
  basicInformationSchema,
  BasicInformationSchema,
} from '../../create/schema'
import { duplicateFields } from '../../store'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}
const BasicInformation = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, editFlag, hasPermission } =
    props
  const [isEditing, setIsEditing] = useState(false)
  const { masterData } = useMasterData()
  const [accountPage, setAccountPage] = useState(1)
  const [duplicates, setDuplicates] = useState<any>({})
  const [isAccountOpen, setIsAccountOpen] = useState(false)

  const getAcccount = async (search?: string, page?: number) => {
    const data = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? accountPage + 1 : 0
    setAccountPage(next)
    return data?.results
  }

  const handleAction = () => {
    setIsAccountOpen(true)
  }

  const onInit = () => {
    return {
      first_name: data?.default_contact?.first_name?.toUpperCase() ?? '',
      last_name: data?.default_contact?.last_name?.toUpperCase() ?? '',
      pan_no: data?.pan_number ?? '',
      date_of_birth: data?.date_of_birth ?? null,
      account_name: data?.primary_company?.business_name ?? '',
      preferred_language_id: data?.preferred_language?.id ?? '',
      preferred_language: data?.preferred_language?.language_name ?? '',
      customer_tier: data?.tier ?? '',
      datetime_created: convertUTCtoBrowserTimeZone(data?.datetime_created),
      datetime_updated: convertUTCtoBrowserTimeZone(data?.datetime_updated),
    }
  }

  const methods = useForm<BasicInformationSchema>({
    resolver: zodResolver(basicInformationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })

  const formBuilderProps = [
    {
      ...textField(
        'first_name',
        'First Name',
        'Enter first name',
        data?.default_contact?.first_name,
        true,
        true
      ),
      toCapitalize: true,
    },
    {
      ...textField(
        'last_name',
        'Last Name',
        'Enter last name',
        data?.default_contact?.last_name,
        false,
        true
      ),
      toCapitalize: true,
    },
    {
      ...textField(
        'pan_no',
        'PAN Number',
        'Enter PAN NUMBER',
        data?.pan_number,
        false,
        true
      ),
      toCapitalize: true,
    },
    {
      ...textField(
        'date_of_birth',
        'Date of Birth',
        'Enter date of birth',
        data?.date_of_birth
          ? moment(data?.date_of_birth).format('DD-MM-YYYY')
          : '--'
      ),
      maxDate: new Date(),
      type: 'date',
      required: false,
    },
    {
      name: 'account_name',
      label: 'Primary Account',
      required: true,
      getData: getAcccount,
      async: true,
      id: 'account_id',
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Primary Account',
      actionLabel: !checkAccountPermission('add') ? '' : 'Add New',
      handleAction: handleAction,
      value: data?.primary_company?.business_name,
    },
    {
      name: 'preferred_language',
      label: 'Preferred Language',
      id: 'preferred_language_id',
      desc: 'language_name',
      descId: 'id',
      data: masterData.languages,
      type: 'custom_search_select',
      placeholder: 'Enter preferred language',
      value: data?.preferred_language?.language_name,
      required: false,
    },
    {
      name: 'customer_tier',
      label: 'Contact tier',
      id: 'id',
      desc: 'tier_name',
      descId: 'id',
      data: masterData?.tier?.map((item: any) => ({
        ...item,
        id: item.tier_code,
      })),
      type: 'custom_search_select',
      placeholder: 'Enter Contact tier',
      value: data?.tier,
      required: false,
    },
    {
      ...textField(
        'datetime_created',
        'Created On',
        'Created On',
        convertUTCtoBrowserTimeZone(data?.datetime_created)
      ),
      disabled: true,
    },
    {
      ...textField(
        'datetime_updated',
        'Updated On',
        'Updated On',
        convertUTCtoBrowserTimeZone(data?.datetime_updated)
      ),
      disabled: true,
    },

    // textField('customer_tier', 'Contact tier', 'Contact tier', data?.tier),
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditContact(handleSubmission)
  const onSubmit = (data: BasicInformationSchema) => {
    mutate({
      input: {
        ...data,
        first_name: data?.first_name?.toUpperCase(),
        last_name: data?.last_name?.toUpperCase(),
        type: 'basic_info',
      },
      id,
    })
  }
  const { handleSubmit } = methods

  const handleDuplicate = async (fieldName: string, value?: string) => {
    const result = await getContactDuplicateData(fieldName, value, id)
    setDuplicates({ [fieldName]: result ?? [] })
  }

  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }

  const handleCallback = (res: any) => {
    methods.setValue('account_id', res?.data?.id)
    methods.setValue('account_name', res?.data?.business_name)
  }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  // const linkDatas = [
  //   ...(data?.lead?.id
  //     ? [
  //         {
  //           label: 'Connected Lead',
  //           value: data?.lead?.name,
  //           id: data?.lead?.id,
  //           slug: 'lead',
  //         },
  //       ]
  //     : []),
  // ]
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={isEditing}
              duplicates={duplicates}
              duplicateFields={duplicateFields}
              clearDuplicates={handleClearDuplicates}
              getDuplicates={handleDuplicate}
            />
          </FormProvider>
        ) : (
          <>
            {formBuilderProps.map((basicInformation) => (
              <div key={data?.id} className=" col-span-2 md:col-span-1">
                <FormFieldView
                  value={basicInformation.value}
                  type={basicInformation?.type}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                />
              </div>
            ))}
            {/* <FormLinkView data={linkDatas} /> */}
          </>
        )}
      </>
    )
  }
  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        {hasPermission && (
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    isLoading={isLoading}
                    outlined={false}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : !editFlag ? (
              <Icons onClick={handleEditClick} name="edit" />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
      <CreateAccount
        isDrawerOpen={isAccountOpen}
        subSection={true}
        handleCallback={handleCallback}
        handleClose={() => setIsAccountOpen(false)}
      />
    </div>
  )
}

export default BasicInformation
