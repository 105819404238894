import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

// import { getOrderDetails } from '../../api'
import BasicInformation from './basicInformation'
import PricingInformation from './pricingInformation'
import { checkPermission } from '../../permission'

const ContactProfiles = () => {
  const { setActiveTab, handleCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    detailsData?: any
  }>()
  const [data, setData] = useState<any>()
  const params = useParams()

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])

  // const getData = async () => {
  //   const { data } = await getOrderDetails(params.id)
  //   setData(data)
  // }
  const handleRefresh = () => {
    // getData()
    handleCallBack?.()
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg dark:bg-[#535050] gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? ''}
        hasPermission={checkPermission('change')}
        handleRefresh={handleRefresh}
      />
      <PricingInformation
        data={data}
        // id={params.id ?? ''}
        // handleRefresh={handleRefresh}
      />
    </div>
  )
}

export default ContactProfiles
