import { BreadCrumb } from '../components/common'

import Header from './header'
import SideNav from './sidenav'

export default function Layout({ children }: any) {
  return (
    <div
      className={`flex h-screen bg-mainBgColor dark:bg-gray-700 dark:text-gray-100`}
    >
      <div className="flex-1 flex  overflow-hidden relative">
        <SideNav />
        <div className="flex h-full w-full overflow-auto flex-col">
          <Header />
          <main
            className={` dark:bg-gray-700 dark:text-gray-100 bg-mainBgColor
             flex flex-col w-full h-full overflow-x-hidden overflow-y-auto  py-4`}
          >
            <BreadCrumb />
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}
