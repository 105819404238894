import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  getEmployeeList,
  getFranchEmployeeList,
} from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import {
  getActivityList,
  getCollectibles,
  getDeliverables,
  getStakeholder,
  useAddActivity,
} from '../api'
import { ActivitySchema, activitySchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: () => void
  id?: string
  orderId: string
  orderCre: any
  isTeamLead?: boolean
}

export default function Create({
  isDrawerOpen,
  handleClose,
  id,
  orderId,
  orderCre,
  handleCallback,
  isTeamLead,
}: Props) {
  const [stakeholderPage, setStakeholderPage] = useState(1)
  const [activityPage, setActivityPage] = useState(1)
  const [collectiblesPage, setCollectiblesPage] = useState(1)
  const [deliverablesPage, setDeliverablesPage] = useState(1)
  const [manageType, setManageType] = useState<string | null>(null)
  const [employeePage, setEmployeePage] = useState(1)
  const [hidden, setHidden] = useState(false)
  const [stakeholderData, setStakeholderData] = useState<any>()

  const [
    requiresClientConfirmationDisable,
    setRequiresClientConfirmationDisable,
  ] = useState(false)
  const [
    requiresStakeholderConfirmationDisable,
    setRequiresStakeholderConfirmation,
  ] = useState(false)
  const [requiresClientApprovalDisable, setRequiresClientApproval] =
    useState(false)
  const [requiresStakeholderApprovalDisable, setRequiresStakeholderApproval] =
    useState(false)

  const [reqClientConfirmationCheck, setReqClientConfirmationCheck] = useState<
    boolean | undefined
  >(false)
  const [reqStakeholderConfirmationCheck, setReqStakeholderConfirmationCheck] =
    useState<boolean | undefined>(false)
  const [reqClientApprovalCheck, setReqClientApprovalCheck] = useState<
    boolean | undefined
  >(false)
  const [reqStakeholderApprovalCheck, setReqStakeholderApprovalCheck] =
    useState<boolean | undefined>(false)

  const methods = useForm<ActivitySchema>({
    resolver: zodResolver(activitySchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      task_name: '',
      days_for_completion: 1,
    },
  })

  useEffect(() => {
    methods.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const stakeholderName = methods.watch('stakeholder_name')

    const updateConfirmationFlags = (
      item: any,
      changeKey: string,
      setCheck: any,
      setDisabled: any,
      setValueKey: any,
      defaultValue: boolean | undefined
    ) => {
      if (item[changeKey] === false) {
        requiresClientConfirmation?.forEach((confirmationItem) => {
          if (confirmationItem.value === true) {
            setCheck(true)
            setDisabled(true)
            methods.setValue(setValueKey, defaultValue, {
              shouldValidate: true,
            })
          }
        })
      } else {
        setCheck(undefined)
        setDisabled(false)
        methods.setValue(setValueKey, null, {
          shouldValidate: true,
        })
      }
    }

    if (stakeholderName) {
      const selectedItem = stakeholderData?.find(
        (item: any) => item.stakeholder_name === stakeholderName
      )

      if (selectedItem) {
        updateConfirmationFlags(
          selectedItem,
          'change_requires_client_confirmation',
          setReqClientConfirmationCheck,
          setRequiresClientConfirmationDisable,
          'requires_client_confirmation',
          false
        )

        updateConfirmationFlags(
          selectedItem,
          'change_requires_stakeholder_confirmation',
          setReqStakeholderConfirmationCheck,
          setRequiresStakeholderConfirmation,
          'requires_stakeholder_confirmation',
          false
        )

        updateConfirmationFlags(
          selectedItem,
          'change_requires_client_completion_approval',
          setReqClientApprovalCheck,
          setRequiresClientApproval,
          'requires_client_approval',
          false
        )

        updateConfirmationFlags(
          selectedItem,
          'change_requires_stakeholder_completion_approval',
          setReqStakeholderApprovalCheck,
          setRequiresStakeholderApproval,
          'requires_stakeholder_approval',
          false
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch('stakeholder_name')])

  const getStakeholderData = async (search?: string, page?: number) => {
    const data = await getStakeholder({
      search: search as string,
      page: page as number,
    })
    const next = data.next ? stakeholderPage + 1 : 0
    setStakeholderPage(next)
    setStakeholderData(data?.results)
    return data?.results?.map((item: any) => ({
      ...item,
      stakeholder_name: item.stakeholder_name,
    }))
  }

  const getActivityData = async (search?: string, page?: number) => {
    const { data } = await getActivityList({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })

    const next = data.next ? activityPage + 1 : 0
    setActivityPage(next)

    return data?.results?.map((item: any) => ({
      ...item,
      display_name: item.display_name,
    }))
  }

  const getCollectiblesData = async (search?: string, page?: number) => {
    const { data } = await getCollectibles({
      search: search as string,
      page: page as number,
    })

    const next = data.next ? collectiblesPage + 1 : 0
    setCollectiblesPage(next)

    return data?.results?.map((item: any) => ({
      ...item,
      collectibles_display_name: item.display_name,
    }))
  }

  const getDeliverablesData = async (search?: string, page?: number) => {
    const { data } = await getDeliverables({
      search: search as string,
      page: page as number,
    })

    const next = data.next ? deliverablesPage + 1 : 0
    setDeliverablesPage(next)

    return data?.results?.map((item: any) => ({
      ...item,
      deliverables_display_name: item.display_name,
    }))
  }

  const getEmployeeData = async (search?: string, page?: number) => {
    if (manageType) {
      const data =
        manageType === 'vendor'
          ? await getEmployeeList({
              search: search as string,
              page: page as number,
              type: 'dropdown',
            })
          : await getFranchEmployeeList(
              {
                search: search as string,
                page: page as number,
                type: 'dropdown',
              },
              orderId
            )

      const next = data.next ? employeePage + 1 : 0
      setEmployeePage(next)

      return data?.results?.map((item: any) => ({
        ...item,
        employee_name: item.name,
      }))
    }
    return []
  }

  const requiresClientConfirmation = [
    {
      id: 1,
      radioLabel: 'Yes',
      value: true,
    },
    {
      id: 2,
      radioLabel: 'No',
      value: false,
      checked: reqClientConfirmationCheck,
    },
  ]

  const requiresStakeholderConfirmation = [
    {
      id: 3,
      radioLabel: 'Yes',
      value: true,
    },
    {
      id: 4,
      radioLabel: 'No',
      value: false,
      checked: reqStakeholderConfirmationCheck,
    },
  ]

  const requiresClientApproval = [
    {
      id: 5,
      radioLabel: 'Yes',
      value: true,
    },
    {
      id: 6,
      radioLabel: 'No',
      value: false,
      checked: reqClientApprovalCheck,
    },
  ]

  const requiresStakeholderApproval = [
    {
      id: 7,
      radioLabel: 'Yes',
      value: true,
    },
    {
      id: 8,
      radioLabel: 'No',
      value: false,
      checked: reqStakeholderApprovalCheck,
    },
  ]

  const showForCustomer = [
    {
      id: 'true',
      name: 'Yes',
      desc_name: 'Yes',
    },
    {
      id: 'false',
      name: 'No',
      desc_name: 'No',
    },
  ]
  const handleCallBack = (e: any) => {
    methods.setValue('assignee_name', null)
    methods.setValue('assignee_id', null)

    setEmployeePage(1)
    if (e.manage_type) {
      setManageType(e.manage_type)
      if (e.manage_type !== 'vendor') {
        methods.setValue('assignee_name', orderCre?.name)
        methods.setValue('assignee_id', orderCre?.id)
      }
    } else {
      setManageType(null)
    }
  }
  const handleAction = () => {
    methods.reset({ ...methods.watch(), task_id: '', task_name: null })
    setHidden(!hidden)
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false,
    hidden = false,
    data?: any,
    actionLabel?: string,
    handleAction?: () => void
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
    hidden: hidden,
    data: data,
    actionLabel: actionLabel,
    handleAction: handleAction,
  })

  const formBuilderProps = [
    {
      name: 'stakeholder_name',
      label: 'Activity Stakeholder',
      required: true,
      getData: getStakeholderData,
      handleCallBack: handleCallBack,
      async: true,
      id: 'stakeholder_id',
      paginationEnabled: true,
      nextBlock: stakeholderPage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'stakeholder_name',
      type: 'auto_complete',
      placeholder: 'Enter Activity Stakeholder',
    },
    textField(
      'task_id',
      'Activity Name',
      'Enter Activity Name',
      true,
      'text',
      false,
      !hidden,
      '',
      'Use Existing',
      () => handleAction()
    ),
    {
      name: 'task_id',
      label: 'Activity Name',
      required: true,
      getData: getActivityData,
      async: true,
      id: 'task_name',
      paginationEnabled: true,
      nextBlock: activityPage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'display_name',
      type: 'auto_complete',
      placeholder: 'Enter Activity Name',
      actionLabel: 'Create Custom',
      handleAction: handleAction,
      hidden: hidden,
    },
    textField(
      'days_for_completion',
      'Expected Days For Completion',
      'Enter Expected Days For Completion',
      true,
      'number',
      false,
      !hidden
    ),
    textField('remarks', 'Remarks', 'Enter Remarks', false, 'textarea'),
    {
      name: 'show_for_customer_name',
      label: 'Visible to Customer',
      required: true,
      data: showForCustomer,
      async: true,
      id: 'show_for_customer',
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'desc_name',
      type: 'custom_search_select',
      placeholder: 'Enter Visible to Customer',
    },
    textField(
      'requires_client_confirmation',
      'Requires Client Confirmation',
      '',
      true,
      'radio',
      requiresClientConfirmationDisable,
      false,
      requiresClientConfirmation
    ),
    textField(
      'requires_stakeholder_confirmation',
      'Requires Stakeholder Confirmation',
      '',
      true,
      'radio',
      requiresStakeholderConfirmationDisable,
      false,
      requiresStakeholderConfirmation
    ),
    textField(
      'requires_client_approval',
      'Requires Client Approval for Completion',
      '',
      true,
      'radio',
      requiresClientApprovalDisable,
      false,
      requiresClientApproval
    ),
    textField(
      'requires_stakeholder_approval',
      'Requires Stakeholder Approval for Completion',
      '',
      true,
      'radio',
      requiresStakeholderApprovalDisable,
      false,
      requiresStakeholderApproval
    ),
    {
      name: 'collectibles',
      label: 'Collectable Requirements',
      required: false,
      getData: getCollectiblesData,
      async: true,
      id: 'collectable_ids',
      paginationEnabled: true,
      nextBlock: collectiblesPage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'collectibles_display_name',
      type: 'multi_select',
      placeholder: 'Enter Collectable Requirements',
      isMultiple: true,
    },
    {
      name: 'deliverables',
      label: 'Deliverable Requirements',
      required: false,
      getData: getDeliverablesData,
      async: true,
      id: 'deliverable_ids',
      paginationEnabled: true,
      nextBlock: deliverablesPage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'deliverables_display_name',
      type: 'multi_select',
      placeholder: 'Enter Deliverable Requirements',
      isMultiple: true,
    },
    {
      name: 'assignee_name',
      label: 'Activity Assignee',
      required: false,
      getData: getEmployeeData,
      async: true,
      id: 'assignee_id',
      paginationEnabled: true,
      nextBlock: employeePage,
      descId: 'id',
      initialLoad: true,
      disabled: !manageType || manageType !== 'vendor',
      desc: 'employee_name',
      type: 'auto_complete',
      placeholder: 'Enter Activity Assignee',
      hidden: !isTeamLead,
    },
  ]
  console.log(methods.watch())
  const handleSubmission = () => {
    handleClose()
    handleClearAndClose()
    handleCallback?.()
    setHidden(false)
  }
  const { mutate, isLoading } = useAddActivity(handleSubmission)
  const onSubmit = (data: ActivitySchema) => {
    const collectable_ids = data?.collectibles?.map((item: any) => item.id)
    const deliverable_ids = data?.deliverables?.map((item: any) => item.id)

    const input = {
      task_id: !hidden ? data?.task_name : undefined,
      task_name: hidden ? data?.task_id : undefined,
      stakeholder_id: data?.stakeholder_id,
      days_for_completion: hidden ? data?.days_for_completion : undefined,
      remarks: data?.remarks,
      assignee_id: data?.assignee_id,
      collectable_ids: collectable_ids,
      deliverable_ids: deliverable_ids,
      requires_client_confirmation: data?.requires_client_confirmation,
      requires_stakeholder_confirmation:
        data?.requires_stakeholder_confirmation,
      requires_client_approval: data?.requires_client_approval,
      requires_stakeholder_approval: data?.requires_stakeholder_approval,
      show_for_customer: JSON.parse(data?.show_for_customer),
    }
    if (orderId) {
      mutate({ input: input, serviceId: id, orderId: orderId })
    }
  }

  const { handleSubmit } = methods
  const handleClearAndClose = () => {
    setHidden(false)
    methods.reset({
      requires_client_confirmation: null,
      requires_stakeholder_confirmation: null,
      requires_client_approval: null,
      requires_stakeholder_approval: null,
      days_for_completion: 1,
    })
    setReqClientConfirmationCheck(false)
    setReqStakeholderConfirmationCheck(false)
    setReqClientApprovalCheck(false)
    setReqStakeholderApprovalCheck(false)
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={() => handleSubmit(onSubmit)()}
      title="Add Activity"
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
