import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { getCountryState } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { router_config } from '../../../configs/route.config'
import { useMasterData } from '../../../store/masterDataStore'
import { getAllServiceList } from '../../order/api'
import {
  getbusIndusData,
  getbusinsector,
  getLeadSource,
  useCreateLead,
} from '../api'
import { getLeadInfoDuplicateData } from '../common/commonUtils'
import { duplicateFields } from '../store'
import { leadSchema, LeadSchema } from './schema'
import moment from 'moment'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh: () => void
}

export default function Create({
  isDrawerOpen,
  handleClose,
  handleRefresh,
}: Props) {
  const navigate = useNavigate()

  const { masterData } = useMasterData()
  const [saveContinue, setSaveContinue] = useState(false)
  const [duplicates, setDuplicates] = useState<any>({})
  const defaultPages: Record<string, number> = {
    industry: 1,
    subIndustry: 1,
    service: 1,
    sector: 1,
  }
  const methods = useForm<LeadSchema>({
    resolver: zodResolver(leadSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [pages, setPages] = useState(defaultPages)
  const { sector_id } = methods.watch()

  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state
  }
  const serviceList = async (value?: string, page?: number) => {
    const { data } = await getAllServiceList({
      search: value ?? '',
      page: page as number,
    })
    setPages((prev) => ({
      ...prev,
      service: data?.next ? prev['service'] + 1 : 0,
    }))
    return data?.results?.map((item: any) => ({
      ...item,
      service_name: item.service_name,
    }))
  }
  const getLead = async (value?: string, page?: number) => {
    const { data } = await getLeadSource({
      search: value ?? '',
      page: page as number,
    })
    return data?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }
  const getBusinessSector = async (value?: string, page?: number) => {
    const { data } = await getbusinsector({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      sector: data?.next ? prev['sector'] + 1 : 0,
    }))
    return data?.map((item: any) => ({
      ...item,
      business_sector_name: item.business_sector_name, // descname
      sectorId: item.id,
    }))
  }
  const getIndustry = async (value?: string, page?: number) => {
    const { data } = await getbusIndusData({
      search: value ?? '',
      page: page as number,
      industry_only: true,
      business_sector_id: sector_id,
    })
    setPages((prev) => ({
      ...prev,
      industry: data?.next ? prev['industry'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      industry_name: item.business_industry_display_name,
    }))
  }
  const handleSectorCalBack = () => {
    methods.setValue('industry_name', '', { shouldValidate: true })
    methods.setValue('industry_id', '', { shouldValidate: true })
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false,
    isMobileOrEmail = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
    ...(isMobileOrEmail ? { isMobileOrEmail: true } : {}),
  })
  const formBuilderProps = [
    {
      ...textField('name', 'Full Name', 'Enter Lead Name', true, true),
      toCapitalize: true,
    },
    {
      ...textField(
        'business_name',
        'Business Name',
        'Enter Business Name',
        false,
        true
      ),
      toCapitalize: true,
    },
    {
      name: 'sector_name',
      label: 'Sector',
      getData: getBusinessSector,
      async: true,
      id: 'sector_id',
      descId: 'id',
      initialLoad: true,
      desc: 'business_sector_name',
      type: 'auto_complete',
      placeholder: 'Enter Sector',
      paginationEnabled: true,
      nextBlock: pages.sector ?? undefined,
      handleCallBack: handleSectorCalBack,
    },
    {
      name: 'industry_name',
      label: 'Industry',
      getData: getIndustry,
      async: false,
      id: 'industry_id',
      disabled: !sector_id,
      descId: 'id',
      initialLoad: true,
      desc: 'industry_name',
      type: 'auto_complete',
      placeholder: 'Enter Industry',
      paginationEnabled: true,
      nextBlock: pages.industry ?? undefined,
    },
    textField('phone', 'Mobile', 'Enter Mobile', false, true, true),
    {
      ...textField('email', 'Email', 'Enter email', false, true, true),
      type: 'email',
    },
    {
      name: 'service',
      label: 'Interested Services',
      required: false,
      getData: serviceList,
      async: true,
      id: 'service_id',
      descId: 'id',
      desc: 'service_name',
      placeholder: 'Enter Interested Service',
      paginationEnabled: true,
      nextBlock: pages.service ?? undefined,
      type: 'multi_select',
      isMultiple: true,
    },
    {
      ...textField('target_date', 'Target Date', 'Enter Target Date', true),
      minDate: new Date(),
      type: 'date',
    },
    {
      ...textField(
        'next_followup_date',
        'Next Followup Date',
        'Enter Next Followup Date',
        true
      ),
      minDate: new Date(),
      type: 'date',
    },
    {
      name: 'state',
      label: 'State',
      required: false,
      getData: getState,
      async: false,
      id: 'state',
      descId: 'state',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter state',
    },

    textField('city', 'City', 'Enter City', false),

    {
      name: 'language_name',
      label: 'Preferred Language',
      id: 'preferred_language_id',
      desc: 'language_name',
      descId: 'id',
      data: masterData.languages,
      type: 'custom_search_select',
      placeholder: 'Enter Preferred Language',
    },

    {
      name: 'lead_source_name',
      label: 'Lead Source',
      required: false,
      getData: getLead,
      async: false,
      id: 'lead_source_id',
      descId: 'id',
      initialLoad: true,
      desc: 'lead_source_name',
      type: 'auto_complete',
      placeholder: 'Enter Lead Source',
      paginationEnabled: true,
      nextBlock: pages.employee ?? undefined,
    },
    textField('probability', 'Probability %', 'Enter Probability %', false),
  ]

  const handleSubmission = (data: any) => {
    methods.reset({
      name: '',
      business_name: '',
      probability: '',
      phone: '',
      email: '',
      city: '',
    })
    if (!saveContinue) {
      navigate(`${router_config.LEADS.path}/${data?.id}/summary`)
      handleRefresh()
      handleClearAndClose()
    }
    // handleClose()
  }
  const handleClearAndClose = () => {
    methods.reset({
      name: '',
      business_name: '',
      probability: '',
      phone: '',
      email: '',
      city: '',
    })
    handleClose()
  }

  const { mutate, isLoading } = useCreateLead(handleSubmission)
  const onSubmit = (data: LeadSchema, saveAndContinue: boolean) => {
    const resultIds = data?.service?.map((entry) => entry.id)
    const updatedData = {
      name: data?.name != ('' || undefined) ? data?.name?.toUpperCase() : null,
      next_followup_date: data?.next_followup_date
        ? moment(data?.next_followup_date).format('YYYY-MM-DD')
        : null,
      target_date: data?.target_date
        ? moment(data?.target_date).format('YYYY-MM-DD')
        : null,
      phone: data?.phone != ('' || undefined) ? data?.phone : null,
      email: data?.email && data?.email !== '' ? data?.email : null,
      state: data?.state != ('' || undefined) ? data?.state : null,
      city: data?.city != ('' || undefined) ? data?.city : null,
      business_name:
        data?.business_name != ('' || undefined) ? data?.business_name : null,
      service: data?.service != undefined ? resultIds : [],
      industry_id:
        data?.industry_id != ('' || undefined) ? data?.industry_id : null,
      sector_id: data?.sector_id != ('' || undefined) ? data?.sector_id : null,
      probability:
        data?.probability && data?.probability !== ''
          ? data?.probability
          : null,
      preferred_language_id:
        data?.preferred_language_id != ('' || undefined)
          ? data?.preferred_language_id
          : null,
      lead_source_id:
        data?.lead_source_id != ('' || undefined) ? data?.lead_source_id : null,
    }
    setSaveContinue(saveAndContinue)
    mutate(
      { ...updatedData },
      {
        onSuccess: (res: any) => {
          saveAndContinue ? handleRefresh() : handleSubmission(res.data)
        },
      }
    )
  }
  const { handleSubmit } = methods
  const handleDuplicate = async (fieldName: string, value?: string) => {
    const newItemList: any[] = []
    const result = await getLeadInfoDuplicateData(fieldName, value)
    if (result?.length > 0) {
      result.map((item: any) => {
        newItemList.push({
          name: item.name,
          phone: item?.phone,
          phone2: item?.lead_additional_data?.phone2,
          email: item?.email,
          email2: item?.lead_additional_data?.email2,
          business_name: item?.business_name,
          service_name: item?.service_name,
        })
      })
    }
    setDuplicates({ [fieldName]: newItemList ?? [] })
  }
  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSaveAndContinue={handleSubmit((data: any) => onSubmit(data, true))}
      handleSubmit={handleSubmit((data: any) => onSubmit(data, false))}
      title="Create New Lead"
      actionLoader={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder
            data={formBuilderProps}
            edit={true}
            duplicates={duplicates}
            duplicateFields={duplicateFields}
            clearDuplicates={handleClearDuplicates}
            getDuplicates={handleDuplicate}
          />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
