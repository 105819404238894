import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import apiUrl from '../../../../apis/api.url'
import CommonNotes from '../../../../components/app/notes/index'
import { checkDetailPermission } from '../../permission'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'

export default function OrderNote() {
  const { setActiveTab, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    detailsData: any
  }>()
  const [detailData, setDetailData] = useState<any>()
  useEffect(() => {
    setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_NOTES')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setDetailData(detailsData)
  }, [detailsData])
  return (
    <CommonNotes
      moduleUrl={apiUrl.ORDER_LIST_URL}
      disabled={
        blockActionsbyStatus(detailData?.order_status?.code, [
          'cancelled',
          'order_cancelled',
          'rejected',
          'order_completed_payment_done',
        ]) && detailData?.is_locked
      }
      checkModulePermission={checkDetailPermission}
    />
  )
}
