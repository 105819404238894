import { z } from 'zod'

export const orderEditSchemaFull = z.object({
  account_name: z.string().refine((val) => val !== '', {
    message: 'Account is required',
  }),
  account_id: z
    .string()
    .refine((val) => val !== '', { message: 'Account is required' }),
  contact_name: z.string().refine((val) => val !== '', {
    message: 'Contact is required',
  }),
  contact_id: z
    .string()
    .refine((val) => val !== '', { message: 'Contact is required' }),
  order_date: z
    .union([
      z.date({ invalid_type_error: 'Order date is required' }),
      z.string({ invalid_type_error: 'Order date is required' }),
    ])
    .refine((val) => val !== '', {
      message: 'Order date is required',
    }),

  internal_campaign_id: z.string().optional().nullable(),
  spot_incentive: z
    .union([
      z.string(),
      // .transform((val) =>
      //   val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
      // )
      // .refine((val) => val !== null && val && val >= 0, {
      //   message: 'Invalid number format',
      // }),
      z.number(),
      z.null(),
    ])
    .optional(),

  lead_source_name: z.string().optional().nullable(),
  lead_source_id: z.string().optional().nullable(),
  assigned_to: z
    .string()
    .refine((val) => val !== '', { message: 'Order CRE is required' }),

  assigned_to_name: z
    .string()
    .refine((val) => val !== '', { message: 'Order CRE is required' }),
  order_comment: z.string().optional().nullable(),
  invoice_note: z.string().optional().nullable(),
  external_id: z.string().optional().nullable(),
  service_name: z.string().optional().nullable(),
  service_id: z.string().optional().nullable(),

  // Edit Service
  order_number: z.string().optional().nullable(),
  total_services_count: z.number().optional().nullable(),
  datetime_created: z.union([z.date(), z.string()]).optional().nullable(),
  created_by: z.string().optional().nullable(),
  order_status_name: z
    .string()
    .refine((val) => val !== '', { message: 'Order Status is required' }),
  order_status_id: z
    .string()
    .refine((val) => val !== '', { message: 'Order Status is required' }),
})
export const orderEditSchema = orderEditSchemaFull
export type OrderEditSchema = z.infer<typeof orderEditSchema>

export const formSchema = orderEditSchemaFull.omit({
  order_status_name: true,
  order_status_id: true,
})
export type OrderSchema = z.infer<typeof formSchema>

export const itemSchema = z.object({
  discount_percent: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Discount percent must be non-negative')
    .max(100, 'Discount percent cannot exceed 100'),
  govt_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Government fee must be a non-negative number'),
  gst_percent: z
    .number({ invalid_type_error: 'Required' })
    .min(0.01, 'GST percent must be greater than 0')
    .max(100, 'GST percent cannot exceed 100'),
  id: z
    .string()
    .refine((val) => val !== '', { message: 'Service Name is required' }),
  service_name: z
    .string()
    .refine((val) => val !== '', { message: 'Service Name is required' }),
  serviceName: z.string().optional().nullable(),
  service_id: z.string().optional().nullable(),
  professional_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Professional fee must be a non-negative number'),
  vendor_fee: z
    .number()
    .nonnegative({ message: 'Vendor fee must be a non-negative number.' })
    .optional()
    .or(z.null()),

  rounding: z.string().optional().nullable(),
  service_total: z.string().optional().nullable(),
})
export const serviceItemSchema = itemSchema.omit({
  service_name: true,
  id: true,
})
export type ServiceItemSchema = z.infer<typeof serviceItemSchema>

const arrayOfItemsSchema = z.array(itemSchema)
export const orderItemSchema = arrayOfItemsSchema
export type OrderItemShema = z.infer<typeof itemSchema>

export const getMergedSchema = (condition?: boolean) => {
  return condition
    ? formSchema.extend({
        items: arrayOfItemsSchema,
      })
    : formSchema
}
