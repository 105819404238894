import { QbsTable } from 'qbs-react-grid'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import Icons from '../../components/common/icons'
import ListingHeader from '../../components/common/ListingTiles/index'
// import { router_config } from '../../configs/permissionGate'
import { router_config } from '../../configs/route.config'
import { useStakeholderFilterStore } from '../../store/filterSore/stakeholderStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { statusClassGen } from '../../utilities/generators'
import { useStakeholder } from './api'
import { checkPermission } from './permission'
import { getColumns } from './stakeholderColumn'

const Stakeholder = () => {
  const navigate = useNavigate()

  const { pageParams, setPageParams } = useStakeholderFilterStore()
  const { page, page_size, search, ordering } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const { data, isLoading, isFetching } = useStakeholder({
    ...searchParams,
    manage_type: 'vendor',
  })

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any) => {
    return `${router_config.SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS.path}/${r.id}/summary`
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const basicData = {
    title: 'Stakeholders',
    icon: 'user',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({ onViewAction: onViewAction, statusClassGen: statusClassGen })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  return (
    <div className="con">
      <ListingHeader data={basicData} />
      {/* <PageTitle data={data?.total} /> */}

      <div className=" p-4">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          isLoading={isLoading || isFetching}
          toolbar
          search
          data={data?.results ?? []}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          height={calcWindowHeight(303)}
          handleResetColumns={() => handleResetColums()}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,

            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          actionProps={[
            {
              title: 'View',
              action: (rowData) => navigate(`${onViewAction(rowData)}`),
              icon: <Icons name="eye" />,
              hidden: !checkPermission('view'),
              toolTip: 'View',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          columnToggle={false}
        />
      </div>
    </div>
  )
}

export default Stakeholder
