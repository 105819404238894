import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

const defaultParams = [
  { title: 'Order CRE', slug: 'order_cre', isChecked: false },
  { title: 'Account CRE', slug: 'account_cre', isChecked: false },
  { title: 'Order Value', slug: 'order_value', isChecked: false },
  { title: 'Amount Paid', slug: 'amount_paid', isChecked: false },
  { title: 'Amount Due', slug: 'amount_due', isChecked: false },
  { title: 'Ageing', slug: 'aging', isChecked: false },
  { title: 'Created Date ', slug: 'created_date', isChecked: false },
  { title: 'Created By', slug: 'created_by', isChecked: false },
  { title: 'Team', slug: 'team', isChecked: false },
  { title: 'Franchisee', slug: 'franchiseee', isChecked: false },
]

export const useOrderFilterStore = create<OrderFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      // Actions
      setAdvanceFilter: (data) => set({ advanceFilter: data }),
      setPageParams: (data) => set({ pageParams: data }),
      setSelectedRows: (data) => set({ selectedRows: data }),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearAdvanceFilter: () =>
        set({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        }),
      clearOrderFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__order_store' }
  )
)

interface OrderFilterstoreType {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  // Actions
  setAdvanceFilter: (data: AdvanceFilter) => void
  setPageParams: (data: StoreFilterParams) => void
  setSelectedRows: (data: (number | string)[]) => void
  clearOrderFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
