import { useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.PAYOUTS, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useSupplier = (input: QueryParams) => {
  return useQuery(['payout', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const deleteSupplier = (id: any) => {
  return deleteData(`${apiUrl.PAYOUTS}/${id}`)
}

export const getSupplierDetails = (id: string) => {
  return getData(`${apiUrl.PAYOUTS}/${id}`)
}

export const addSupplier = (data: any) => {
  return postData(`${apiUrl.PAYOUTS}`, data)
}

export const updateSuppliers = (id: string, data: any) => {
  return updateData(`${apiUrl.PAYOUTS}/${id}`, data)
}
