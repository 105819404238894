import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { AutoComplete } from 'qbs-core'
import { useCallback, useEffect, useState } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'

import {
  fetchAccountContacts,
  getAccounts,
  getEmployeeList,
  getService,
} from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import {
  Button,
  DialogModal,
  Icon,
  TextField,
} from '../../../components/common'
import Icons from '../../../components/common/icons'
import { useSnackbarManager } from '../../../components/common/snackbar'
import config from '../../../config'
import { checkPermissionAny } from '../../../configs/permissionGate'
import {
  getErrorMessage,
  getServiceTotal,
  numberFormat,
} from '../../../utilities/parsers'
import CreateAccount from '../../accounts/create'
import { checkPermission } from '../../accounts/permission'
import CreateContact from '../../contacts/create/index'
import { add_orders, getLeadSource } from '../api'
import { checkDetailPermission } from '../permission'
import { getMergedSchema } from './orderSchema'

type Props = {
  isOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: (data: any) => void
  handleRefresh?: () => void
  parentInfo?: {
    id?: string
    name?: string
    active_order_count?: any
    account_cre: any
    account_cre_id: any
  }
}

type FieldsType =
  | 'discount_percent'
  | 'govt_fee'
  | 'gst_percent'
  | 'professional_fee'
  | 'vendor_fee'
export default function CreateOrder({
  isOpen,
  handleClose,
  subSection,
  handleCallback,
  handleRefresh,
  parentInfo,
}: Props) {
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const [isContactOpen, setIsContactOpen] = useState(false)
  const [serviceItems, setServiceItems] = useState<any>({})
  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [pageParams, setPageParams] = useState({
    accountPage: 1,
    employeePage: 1,
    servicePage: 1,
  })
  const { accountPage, employeePage, servicePage } = pageParams
  const handleTabSwitch = (panel: string) => {
    setExpanded(panel)
  }
  const itemBool = Object.keys(serviceItems)?.length > 0 ? true : false
  const formSchema = getMergedSchema(itemBool)

  const methods = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { account_id, account_name, items } = methods.watch()

  const getAcccount = async (search?: string, page?: number) => {
    const data = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? accountPage + 1 : 0
    setPageParams({ ...pageParams, accountPage: next })
    return data?.results
  }
  const getContact = async (search?: string, page?: number) => {
    const { data } = await fetchAccountContacts(
      {
        search: search as string,
        page: page as number,
        type: 'dropdown',
      },
      account_id
    )
    return data?.map((items: any) => ({
      ...items,
      name:
        items.customer_info.first_name + ' ' + items.customer_info.last_name,
    }))
  }
  const getEmployee = async (search?: string, page?: number) => {
    const data = await getEmployeeList({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? employeePage + 1 : 0
    setPageParams({ ...pageParams, employeePage: next })
    return data?.results
  }
  const getLeadSourceList = async (search?: string) => {
    const { data } = await getLeadSource({
      search: search as string,
    })
    return data
  }

  const getServiceList = async (search?: string, page?: number) => {
    const { data } = await getService({
      search: search as string,
      page: page as number,
    })
    const next = data.next ? servicePage + 1 : 0
    setPageParams({ ...pageParams, servicePage: next })
    return data?.results
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    spacing = 4,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    spacing: spacing,
  })

  const handleAccountAction = () => {
    setIsAccountOpen(true)
  }
  const handleContactAction = () => {
    setIsContactOpen(true)
  }
  const handleClear = (val: any) => {
    if (val?.active_order_count > 0) {
      handleUpdate()
    }
    methods.setValue('assigned_to_name', val?.account_cre ?? '', {
      shouldValidate: true,
    })
    methods.setValue('assigned_to', val?.account_cre_id ?? '', {
      shouldValidate: true,
    })
    methods.setValue('contact_name', '')
    methods.setValue('customer_id', '')
  }
  const formBuilderProps = [
    {
      name: 'account_name',
      label: 'Account Selection',
      disabled: subSection,
      spacing: 4,
      required: true,
      getData: getAcccount,
      async: true,
      id: 'account_id',
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Primary Account',
      actionLabel: subSection || !checkPermission('add') ? '' : 'Add New',
      handleAction: handleAccountAction,
      handleCallBack: handleClear,
    },
    {
      name: 'contact_name',
      label: 'Contact Selection',
      required: true,
      getData: getContact,
      async: true,
      id: 'contact_id',
      descId: 'customer_id',
      initialLoad: true,
      spacing: 4,
      disabled: !account_id,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Primary Contact',
      actionLabel:
        !account_id || !checkPermissionAny('add_customer') ? '' : 'Add New',
      handleAction: handleContactAction,
    },

    {
      ...textField('order_date', 'Order Date', 'Enter order date', true),
      maxDate: new Date(),
      disabled: !checkDetailPermission('change', 'date'),
      type: 'date',
      spacing: 4,
    },
    {
      name: 'assigned_to_name',
      label: 'Order CRE',
      required: true,
      getData: getEmployee,
      async: true,
      id: 'assigned_to',
      paginationEnabled: true,
      nextBlock: employeePage,
      spacing: 4,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Order CRE',
    },
    {
      name: 'lead_source_name',
      label: 'Source of Sale',
      required: false,
      getData: getLeadSourceList,
      async: true,
      id: 'lead_source_id',
      descId: 'id',
      desc: 'name',
      type: 'auto_complete',
      spacing: 4,
      placeholder: 'Enter source of sale',
    },

    textField(
      'internal_campaign_id',
      'Internal Campaign',
      'Enter internal campaign',
      false
    ),
    textField(
      'spot_incentive',
      'Spot Incentive',
      'Enter spot incentive',
      false,
      4,
      'number'
    ),
    textField('order_comment', 'Remarks', 'Remarks', false, 8),
  ]
  const serviceItemFields = [
    {
      name: 'service_name',
      label: 'Account Selection',
      required: false,
      getData: getServiceList,
      async: true,
      id: 'account_id',
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      desc: 'service_name',
      type: 'auto_complete',
      placeholder: 'Enter Primary Account',
    },
    textField('professional_fee', '', 'Professional Fee', true, 8, 'number'),
    textField('vendor_fee', '', 'Vender Fee', false, 8, 'number'),
    textField('govt_fee', '', 'Govt Fee', true, 8, 'number'),
    textField('gst_percent', '', 'GST %', true, 8, 'number'),
    textField('discount_percent', '', 'Discount %', true, 8, 'number'),
  ]
  const handleAccountCallback = useCallback((res: any) => {
    if (res) {
      setValue('account_id', res?.data?.id ?? undefined)
      setValue('account_name', res?.data?.business_name, {
        shouldValidate: true,
      })
      setValue('contact_id', '')
      setValue('contact_name', '', {
        shouldValidate: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleContactCallback = useCallback((res: any) => {
    if (res) {
      setValue('contact_id', res?.data?.id)
      setValue(
        'contact_name',
        `${res?.data?.default_contact?.first_name} ${res?.data?.default_contact?.last_name}`,
        {
          shouldValidate: true,
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleAddService = (key: string) => {
    setExpanded(key)
    if (count !== 0) {
      const len = Object.keys(serviceItems)?.length
      const data = { ...serviceItems, [len]: serviceItemFields }
      append({
        discount_percent: '',
        govt_fee: '',
        gst_percent: '',
        id: '',
        service_name: '',
        professional_fee: '',
        vendor_fee: undefined,
      })
      setServiceItems(data)
    }
    setCount(count + 1)
  }
  const {
    control,
    formState: { errors },
    setValue,
  } = methods
  const { enqueueSnackbar } = useSnackbarManager()

  const { append, remove, update } = useFieldArray({
    control: control,
    name: 'items',
  })

  const handleSaveData = (datas: any) => {
    add_orders({
      ...datas,
      lead_source_name: undefined,
      lead_source_id: datas?.lead_source_id ? datas?.lead_source_id : undefined,
      order_date: moment(datas.order_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      assigned_to_name: undefined,
      account_name: undefined,
      contact_name: undefined,
    })
      .then((res) => {
        handleRefresh?.()
        handleCallback?.(res)
        handleClose()
        enqueueSnackbar('Order created successfully', { variant: 'success' })
        setIsLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }

  const onSubmit = (datas: any) => {
    setIsLoading(true)
    const greaterThanOne = true
    datas.items.map((item: any) => {
      if (item?.vendor_fee == undefined) {
        item.vendor_fee = 0
      }
      // const val = getServiceTotal(
      //   item?.professional_fee,
      //   item?.govt_fee,
      //   item?.gst_percent,
      //   item?.discount_percent,
      //   item?.vendor_fee
      //     ? isNaN(Number(item?.vendor_fee))
      //       ? 0
      //       : Number(item?.vendor_fee)
      //     : 0
      // )
      // if (Number(val) <= 0) {
      //   greaterThanOne = false
      // }
    })
    if (!greaterThanOne) {
      enqueueSnackbar('Order total must be greater than 0', {
        variant: 'error',
      })
      return false
    } else {
      handleSaveData(datas)
    }
  }
  const handleTextChange = (e: any, index: number, name: FieldsType) => {
    const { value } = e.target
    setValue(`items.${index}.${name}`, Number(value), { shouldValidate: true })
  }
  const handleAutoCompleteChange = (value: any, index: number) => {
    setValue(`items.${index}.id`, value.id, {
      shouldValidate: true,
    })
    setValue(`items.${index}.service_name`, value.service_name, {
      shouldValidate: true,
    })
    update(index, {
      id: value.id,
      discount_percent: '',
      govt_fee: '',
      gst_percent: '',
      service_name: value?.service_name,
      professional_fee: '',
      vendor_fee: undefined,
    })
  }
  const getRounding = (value: any) => {
    if (!value) {
      return 0.0
    }
    const rounded_total: any = parseFloat(value).toFixed(0)
    const rounding = rounded_total - value
    return rounding
  }

  const handleReturnError = (fieldIndex: number, key?: string) => {
    if ('items' in errors && Array.isArray(errors.items)) {
      const itemError = errors.items[fieldIndex]
      return itemError && key ? itemError[key] : itemError
    }
    return false
  }
  const handleErrorValidation = () => {
    const error = {
      basic: false,
      order: false,
    }
    if ('items' in errors && Array.isArray(errors.items)) {
      error.order = true
    }

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && key !== 'items') {
        error.basic = true
        break
      }
    }

    return error
  }

  const handleDelete = (key: any) => {
    const newItems: any = {}
    let newIndex = 0
    for (const itemKey in serviceItems) {
      if (itemKey !== key) {
        newItems[newIndex] = serviceItems[itemKey]
        newIndex++
      }
    }
    remove(key)
    setServiceItems({ ...newItems })
  }
  const getWholeTotal = (): string => {
    let total = 0
    items?.forEach((item: any) => {
      total += Number(
        parseFloat(
          getServiceTotal(
            item.professional_fee || 0,
            item.govt_fee || 0,
            item.gst_percent || 0,
            item.discount_percent || 0,
            item.vendor_fee || 0
          )
        ).toFixed(0)
      )
    })
    return total.toFixed(2)
  }
  const handleUpdate = async () => {
    const data = await getLeadSourceList()
    const item = data?.find((items: any) => items.name === 'Existing')
    setValue('lead_source_name', item.name)
    setValue('lead_source_id', item.id)
  }

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        account_name: parentInfo ? parentInfo?.name : '',
        account_id: parentInfo ? parentInfo?.id : '',
        order_date: checkDetailPermission('change', 'date')
          ? null
          : moment(new Date()).format('DD-MM-YYYY'),
        contact_id: '',
        contact_name: '',
        internal_campaign_id: '',
        spot_incentive: 0,
        lead_source_name: '',
        lead_source_id: undefined,
        assigned_to: parentInfo ? parentInfo?.account_cre_id : '',
        assigned_to_name: parentInfo ? parentInfo?.account_cre : '',
        order_comment: '',
        items: [],
      })
      append({
        discount_percent: '',
        govt_fee: '',
        gst_percent: '',
        id: undefined,
        service_name: '',
        professional_fee: '',
        // vendor_fee: '',
        vendor_fee: undefined,
      })
      setExpanded('panel1')
      setServiceItems({ 0: serviceItemFields })
    }
    setCount(0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, subSection, parentInfo])

  useEffect(() => {
    if (parentInfo?.active_order_count > 0) {
      handleUpdate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentInfo?.active_order_count])

  useEffect(() => {
    if (handleErrorValidation()?.order && !handleErrorValidation()?.basic) {
      setExpanded('panel2')
    } else if (
      !handleErrorValidation()?.order &&
      handleErrorValidation()?.basic
    ) {
      setExpanded('panel1')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Create Order"
      secondaryAction={handleClose}
      onSubmit={() => methods.handleSubmit(onSubmit)()}
      secondaryActionLabel="Cancel"
      actionLabel="Save"
      actionLoader={isLoading}
      small={false}
      body={
        <div className="flex flex-col">
          <div
            className={`customAccordion border-b border-formBorder ${
              expanded === 'panel1' ? 'expanded' : ''
            }`}
          >
            <div className="flex items-center justify-between py-4 px-5 gap-4 accordionHeader">
              <div
                className="flex items-center gap-2 flex-1 cursor-pointer "
                onClick={() => handleTabSwitch('panel1')}
              >
                <Icon
                  name="drop-arrow"
                  className={`iconWidthSm iconBlack ${
                    expanded === 'panel1' && 'iconRotate'
                  }`}
                />
                <span
                  className={`flex-1 ${
                    handleErrorValidation()?.basic && ' text-bgBadgeColor'
                  }`}
                >
                  {`Basic Info `}
                </span>
              </div>
            </div>
            <div
              className={`accordionDetails  ${
                expanded === 'panel1' ? 'accordionVisible' : 'accordionHidden'
              }`}
            >
              <div className="grid grid-cols-12 gap-4 ">
                <FormProvider {...methods}>
                  <FormBuilder
                    data={formBuilderProps}
                    spacing={true}
                    edit={true}
                    fromPopup={true}
                  />
                </FormProvider>
              </div>
            </div>
          </div>
          <div
            className={`customAccordion border-b border-formBorder ${
              expanded === 'panel2' ? 'expanded' : ''
            }`}
          >
            <div className="flex items-center justify-between py-4 px-5 gap-4 accordionHeader">
              <div
                className="flex items-center gap-2 flex-1 cursor-pointer"
                onClick={() => handleTabSwitch('panel2')}
              >
                <Icon
                  name="drop-arrow"
                  className={`iconWidthSm iconBlack ${
                    expanded === 'panel2' && 'iconRotate'
                  }`}
                />
                <span
                  className={`flex-1 ${
                    handleErrorValidation()?.order && ' text-bgBadgeColor'
                  }`}
                >{`Orders `}</span>
              </div>
              <Button
                label="Add Services"
                icon="plus"
                onClick={() => handleAddService('panel2')}
                size="xs"
                outlined={true}
              />
            </div>
            <div
              className={`accordionDetails flex flex-col ${
                expanded === 'panel2'
                  ? 'accordionVisible min-h-[400px]'
                  : 'accordionHidden'
              }`}
            >
              <div className=" inline-table">
                <div className=" customFieldTable customFieldTable-container">
                  <table
                    className="table w-full"
                    style={{ minWidth: '650px' }}
                    aria-label="simple table"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            minWidth: '350px',
                          }}
                        >
                          Item Name
                        </th>
                        <th>Professional Fee</th>
                        <th>Vendor Fee</th>
                        <th>Govt. Fee</th>
                        <th>GST %</th>
                        <th>Discount %</th>
                        <th>Rounding</th>
                        <th>Total </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(serviceItems)?.length === 0 ? (
                        <tr>
                          <td className="bg-white" colSpan={9}>
                            <div className="flex flex-col items-center justify-center gap-2 py-10">
                              <img
                                src={config.EMPTY_ILLUSTRATION}
                                alt="bizpole"
                                className="h-auto"
                              />
                              <p className="text-sm font-bold text-primaryText">
                                No Services added yet
                              </p>
                              <Button
                                label="Add Services"
                                onClick={() => handleAddService('panel2')}
                                icon="plus"
                                size="xs"
                                outlined={true}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {Object.keys(serviceItems)?.map((key, index) => (
                            <tr key={index}>
                              {serviceItems[key]?.map((fields: any) => (
                                <>
                                  {fields?.type === 'auto_complete' ? (
                                    <th
                                      style={{
                                        minWidth: '350px',
                                      }}
                                    >
                                      <Controller
                                        name={`items.${index}.id`}
                                        control={control}
                                        render={({}) => (
                                          <AutoComplete
                                            name={`items.${index}.id`}
                                            type="auto_complete"
                                            onChange={(e) =>
                                              handleAutoCompleteChange(e, index)
                                            }
                                            value={items[index]?.service_name}
                                            getData={getServiceList}
                                            paginationEnabled={true}
                                            nextBlock={servicePage}
                                            async={true}
                                            errors={handleReturnError(
                                              index,
                                              'id'
                                            )}
                                            placeholder="Item Name"
                                            desc={fields.desc}
                                            descId={fields.descId}
                                          />
                                        )}
                                      />
                                    </th>
                                  ) : (
                                    <td
                                      align="right"
                                      style={{
                                        minWidth: '100px',
                                      }}
                                    >
                                      <Controller
                                        name={fields.name}
                                        control={control}
                                        render={({}) => (
                                          <TextField
                                            id="1"
                                            name={fields.name}
                                            disabled={!items[index]?.id}
                                            type="number"
                                            value={
                                              items[index][fields.name] ?? ''
                                            }
                                            onChange={(e) =>
                                              handleTextChange(
                                                e,
                                                index,
                                                fields?.name
                                              )
                                            }
                                            placeholder=""
                                            // errors={
                                            //   errors?.items &&
                                            //   errors?.items[index]
                                            // }
                                            errors={handleReturnError(index)}
                                            required={false}
                                          />
                                        )}
                                      />
                                    </td>
                                  )}
                                </>
                              ))}

                              <td
                                align="right"
                                className="customFieldTable-cell"
                              >
                                <p className="text-sm font-medium text-primaryText">
                                  {numberFormat(
                                    getRounding(
                                      getServiceTotal(
                                        items[index]?.professional_fee,
                                        items[index]?.govt_fee,
                                        items[index]?.gst_percent,
                                        items[index]?.discount_percent,
                                        items[index]?.vendor_fee as number
                                      )
                                    )
                                  )}
                                </p>
                              </td>
                              <td
                                align="right"
                                className="customFieldTable-cell"
                              >
                                <p className="text-sm font-medium text-primaryText">
                                  {numberFormat(
                                    parseFloat(
                                      getServiceTotal(
                                        items[index]?.professional_fee,
                                        items[index]?.govt_fee,
                                        items[index]?.gst_percent,
                                        items[index]?.discount_percent,
                                        items[index]?.vendor_fee as number
                                      ) as any
                                    ).toFixed(0)
                                  )}
                                </p>
                              </td>
                              <td
                                align="right"
                                className="actionCell cursor-pointer"
                              >
                                {Object.keys(serviceItems)?.length > 1 && (
                                  <Icons
                                    onClick={() => handleDelete(key)}
                                    className="iconBlack iconWidthSm"
                                    name="delete"
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-end mt-4  ">
                  <p className="text-sm text-primaryText font-medium">
                    Order Grand Total:{' '}
                    <span className="text-base text-primaryText font-bold">
                      {numberFormat(getWholeTotal())}
                    </span>
                  </p>
                </div>
              </div>
              {/* <div className="flex items-center justify-end mt-4">
                <p className="text-sm text-primaryText font-medium">
                  Order Grand Total:{' '}
                  <span className="text-base text-primaryText font-bold">
                    {numberFormat(getWholeTotal())}
                  </span>
                </p>
              </div> */}
            </div>
          </div>
          <CreateAccount
            isDrawerOpen={isAccountOpen}
            subSection={true}
            handleCallback={handleAccountCallback}
            handleClose={() => setIsAccountOpen(false)}
          />
          <CreateContact
            isDrawerOpen={isContactOpen}
            subSection={true}
            handleCallback={handleContactCallback}
            parentParams={{ id: account_id, name: account_name }}
            handleClose={() => setIsContactOpen(false)}
          />
        </div>
      }
    />
  )
}
