import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  fetchAccountContacts,
  getAccounts,
  getEmployeeList,
} from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { allowActionsByStatusExcept } from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import { getLeadSource, getOrderStatusList, useEditOrder } from '../../api'
import { orderEditSchema, OrderEditSchema } from '../../create/orderSchema'
import { checkDetailPermission } from '../../permission'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  hasPermission?: boolean
}
const BasicInformation = (props: Props) => {
  const { data, id, handleRefresh, hasPermission } = props
  const [isEditing, setIsEditing] = useState(false)
  const [accountPage, setAccountPage] = useState(1)
  const [contactPage, setContactPage] = useState(1)
  const [employeePage, setEmployeePage] = useState(1)
  const [leadPage, setLeadPage] = useState(1)
  const [statusPage, setStatusPage] = useState(1)

  const onInit = () => {
    return {
      order_date: data?.order_date
        ? moment(data?.order_date).format('YYYY-MM-DD')
        : new Date(),
      order_number: data?.order_number,

      contact_id: data?.contact?.id,
      contact_name: data?.contact?.name,
      account_id: data?.account?.id,
      account_name: data?.account?.business_name,
      assigned_to_name: data?.assigned_to?.name,
      assigned_to: data?.assigned_to?.id,
      lead_source_id: data?.lead_source?.id ?? null,
      lead_source_name: data?.lead_source?.name ?? null,
      internal_campaign_id: data?.internal_campaign_id ?? '',
      total_services_count: data?.total_services_count,
      spot_incentive: data?.spot_incentive,
      datetime_created: convertUTCtoBrowserTimeZone(data?.datetime_created),
      datetime_updated: convertUTCtoBrowserTimeZone(data?.datetime_updated),
      order_status_id: data?.order_status?.id,
      order_status_name: data?.order_status?.name,
      created_by: data?.created_by?.name,
      order_comment: data?.order_comment ?? '',
      invoice_note: data?.invoice_note ?? '',
    }
  }

  const methods = useForm<OrderEditSchema>({
    resolver: zodResolver(orderEditSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { account_id } = methods.watch()

  const getAcccount = async (search?: string, page?: number) => {
    const resp = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = resp.next ? accountPage + 1 : 0
    setAccountPage(next)
    return resp?.results
  }

  const getContact = async (search?: string, page?: number) => {
    const { data } = await fetchAccountContacts(
      {
        search: search as string,
        page: page as number,
        type: 'dropdown',
      },
      account_id
    )
    const next = data.next ? contactPage + 1 : 0
    setContactPage(next)

    return data?.map((items: any) => ({
      ...items,
      name:
        items.customer_info.first_name + ' ' + items.customer_info.last_name,
    }))
  }

  const loadEmployeeList = async (value?: string, page?: number) => {
    const datas = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    const next = datas.next ? employeePage + 1 : 0
    setEmployeePage(next)
    return datas?.results?.map((item: any) => ({
      ...item,
      employee_name: item.name,
    }))
  }

  const getLead = async (value?: string, page?: number) => {
    const { data: resp } = await getLeadSource({
      search: value ?? '',
      page: page as number,
    })
    const next = resp.next ? leadPage + 1 : 0
    setLeadPage(next)
    return resp?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }

  const getStatus = async (value?: string, page?: number) => {
    const resp = await getOrderStatusList({
      search: value ?? '',
      page: page as number,
      list_type: 'dropdown',
    })
    const next = resp.next ? statusPage + 1 : 0
    setStatusPage(next)
    return resp?.data?.map((item: any) => ({
      ...item,
      order_status_name: item.name,
    }))
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    value,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })
  const handleUpdate = async (count: any) => {
    if (count > 0) {
      const data = await getLead()
      const item = data?.find((items: any) => items.name === 'Existing')
      methods.setValue('lead_source_id', item.id)
      methods.setValue('lead_source_name', item.name)
    } else {
      methods.setValue('lead_source_id', null)
      methods.setValue('lead_source_name', null)
    }
  }
  const handleClear = (sel: any) => {
    handleUpdate(sel?.active_order_count)

    methods.setValue('contact_name', '')
    methods.setValue('contact_id', '')
  }
  const formBuilderProps = [
    {
      ...textField(
        'order_date',
        'Order Date',
        'Enter Order Date',
        moment(data?.order_date).format('DD-MM-YYYY')
      ),
      maxDate: new Date(),
      type: 'date',
      disabled: !checkDetailPermission('change', 'date'),
      spacing: 6,
      required: true,
    },
    {
      ...textField(
        'order_number',
        'Order Id',
        'Enter Order Number',
        data?.order_number,
        true,
        'text',
        true
      ),
      spacing: 6,
    },
    {
      name: 'account_name',
      label: 'Account',
      required: true,
      getData: getAcccount,
      link: `${router_config.ACCOUNTS.path}/${data?.account?.id}/summary`,
      async: true,
      id: 'account_id',
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      spacing: 6,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Account',
      value: data?.account?.business_name,
      handleCallBack: handleClear,
    },
    {
      name: 'contact_name',
      label: 'Contact',
      required: true,
      disabled: !account_id,
      getData: getContact,
      async: true,
      id: 'contact_id',
      paginationEnabled: true,
      link: `${router_config.CONTACTS.path}/${data?.contact?.id}/summary`,
      nextBlock: contactPage,
      descId: 'customer_id',
      initialLoad: true,
      desc: 'name',
      spacing: 6,
      type: 'auto_complete',
      placeholder: 'Enter Contact',
      value: data?.contact?.name,
    },
    {
      name: 'assigned_to_name',
      label: 'Order CRE',
      id: 'assigned_to',
      getData: loadEmployeeList,
      async: true,
      spacing: 6,
      initialLoad: true,
      paginationEnabled: true,
      nextBlock: employeePage,
      desc: 'employee_name',
      descId: 'id',
      type: 'auto_complete',
      placeholder: 'Enter Order CRE',
      value: data?.assigned_to?.name,
      required: true,
    },
    {
      name: 'lead_source_name',
      label: 'Source Of Sale',
      id: 'lead_source_id',
      desc: 'lead_source_name',
      descId: 'id',
      getData: getLead,
      async: true,
      initialLoad: true,
      paginationEnabled: true,
      spacing: 6,
      nextBlock: leadPage,
      type: 'auto_complete',
      placeholder: 'Enter Source Of Sale',
      value: data?.lead_source?.name,
      required: false,
    },
    {
      ...textField(
        'internal_campaign_id',
        'Internal Campaign',
        'Enter Internal Campaign',
        data?.internal_campaign_id
      ),
      spacing: 6,
    },
    {
      ...textField(
        'total_services_count',
        'Service Count',
        'Enter Service Count',
        data?.total_services_count,
        true,
        'number',
        true
      ),
      spacing: 6,
    },
    {
      ...textField(
        'spot_incentive',
        'Spot Incentive',
        'Enter Spot Incentive',
        data?.spot_incentive,
        false,
        'text',
        true
      ),
      spacing: 6,
    },
    {
      name: 'order_status_name',
      label: 'Order Status',
      id: 'order_status_id',
      desc: 'order_status_name',
      descId: 'id',
      getData: getStatus,
      async: true,
      initialLoad: true,
      disabled: true,
      type: 'auto_complete',
      placeholder: 'Enter Order Status',
      value: data?.order_status?.name,
      required: true,
      spacing: 6,
    },

    {
      ...textField(
        'datetime_created',
        'Created On',
        'Created On',
        convertUTCtoBrowserTimeZone(data?.datetime_created)
      ),
      spacing: 6,
      required: true,
      disabled: true,
    },

    {
      ...textField(
        'datetime_updated',
        'Updated On',
        'Updated On',
        convertUTCtoBrowserTimeZone(data?.datetime_updated)
      ),

      required: true,
      disabled: true,
      spacing: 6,
    },
    {
      ...textField(
        'created_by',
        'Created By',
        'Enter Created By',
        data?.created_by?.name,
        true,
        'text',
        true
      ),
      spacing: 6,
    },
    ...(data?.parent?.order_number
      ? [
          {
            ...textField(
              'parent_order',
              'Franchisee Order',
              '',
              data?.parent?.order_number,
              false,
              'text'
            ),
            spacing: 6,
          },
        ]
      : []),
    {
      ...textField(
        'order_comment',
        'Remarks',
        'Enter Remarks',
        data?.order_comment,
        false,
        'textarea'
      ),
      spacing: 12,
    },
    {
      ...textField(
        'invoice_note',
        'Invoice Notes',
        'Enter Invoice Notes',
        data?.invoice_note,
        false,
        'textarea'
      ),
      spacing: 12,
    },
  ]

  // const handleEditClick = () => {
  //   setIsEditing(!isEditing)
  // }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }

  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }

  const { mutate, isLoading } = useEditOrder(handleSubmission)
  const onSubmit = (data: OrderEditSchema) => {
    const input = {
      order_date: moment(data?.order_date).format('YYYY-MM-DD'),
      contact_id: data?.contact_id,
      account_id: data?.account_id,
      assigned_to: data?.assigned_to,
      lead_source_id: data?.lead_source_id ? data?.lead_source_id : null,
      internal_campaign_id: data?.internal_campaign_id ?? '',
      spot_incentive: data?.spot_incentive,
      order_status_id: data?.order_status_id,
      order_comment: data?.order_comment ?? '',
      invoice_note: data?.invoice_note ?? '',
    }
    mutate({ input: input, id: id ? id : '' })
  }
  // const linkDatas = [
  //   ...(data?.id && data?.quote_number
  //     ? [
  //         {
  //           label: 'Connected Quote',
  //           value: data?.quote_number,
  //           id: data?.id,
  //           slug: 'quote',
  //         },
  //       ]
  //     : []),
  // ]
  const { handleSubmit } = methods

  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} spacing />
          </FormProvider>
        ) : (
          <>
            {formBuilderProps.map((basicInformation: any) => (
              <div
                key={data?.id}
                className={`col-span-12 md:col-span-${
                  basicInformation.spacing || 12
                }`}
              >
                <FormFieldView
                  value={basicInformation.value}
                  type={basicInformation.type}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                  link={basicInformation.link}
                />
              </div>
            ))}
            <div className=" col-span-12">
              {/* <FormLinkView data={linkDatas} /> */}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div className="bg-white dark:bg-black rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText dark:text-white">
            Basic Information
          </h4>
        </div>
        {hasPermission &&
          (allowActionsByStatusExcept(data?.order_status?.code, [
            'cancelled',
            'order_cancelled',
            'rejected',
            'order_completed_payment_done',
          ]) ||
            !data?.is_locked) && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {/* {hasPermission &&
                    (allowActionsByStatusExcept(data?.order_status?.code, [
                      'cancelled',
                      'order_cancelled',
                      'rejected',
                      'order_completed_payment_done',
                    ]) ||
                      !data?.is_locked) && (
                      <Icons onClick={handleEditClick} name="edit" />
                    )} */}
                </>
              )}
            </div>
          )}
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
