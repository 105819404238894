import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import TaskComponent from '../../../../components/app/task/index'
import { checkDetailPermission } from '../../permission'

export default function AccountTask() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()

  useEffect(() => {
    setActiveTab('ACCOUNT_TASKS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TaskComponent
      model_name="CustomerCompany"
      checkModulePermission={checkDetailPermission}
      createOnly
    />
  )
}
