import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { getAdditionalStatusLog } from '../../api'
import { getColumns } from './logColumns'

const AdditionalStatusLog = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const params = useParams()
  useEffect(() => {
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    setIsLoading(true)
    getLogList()
  }

  const getLogList = () => {
    getAdditionalStatusLog({ order_item_id: params?.id }).then((res) => {
      setData(res)
      setIsLoading(false)
    })
  }

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_STATUS_LOG')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isLoading}
          columns={getColumns() as any}
          columnToggle
        />
      </div>
    </>
  )
}

export default AdditionalStatusLog
