import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { getAccounts, getCountryState } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { router_config } from '../../../configs/route.config'
import { useMasterData } from '../../../store/masterDataStore'
import CreateAccount from '../../accounts/create'
import { checkPermission } from '../../accounts/permission'
import { useCreateContact } from '../api'
import { getContactDuplicateData } from '../common/commonUtils'
import { duplicateFields } from '../store'
import { getMergedSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: (data: any) => void
}

export default function CreateContact({
  isDrawerOpen,
  handleClose,
  parentParams,
  subSection,
  handleCallback: handleAccountCallBack,
  handleRefresh,
}: Props) {
  const { masterData } = useMasterData()
  const [saveContinue, setSaveContinue] = useState(false)
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const navigate = useNavigate()
  const [accountPage, setAccountPage] = useState(1)
  const [duplicates, setDuplicates] = useState<any>({})
  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state
  }
  // let cancelTokenSource: CancelTokenSource
  const getAcccount = async (search?: string, page?: number) => {
    const data = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? accountPage + 1 : 0
    setAccountPage(next)
    return data?.results
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false,
    isMobileOrEmail = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
    ...(isMobileOrEmail ? { isMobileOrEmail: true } : {}),
  })

  const handleAction = () => {
    setIsAccountOpen(true)
  }

  const formBuilderProps = [
    {
      ...textField('first_name', 'First Name', 'Enter first name', true, true),
      toCapitalize: true,
    },
    {
      ...textField('last_name', 'Last Name', 'Enter last name', false, true),
      toCapitalize: true,
    },
    {
      ...textField('date_of_birth', 'Date of Birth', 'Enter date of birth'),
      maxDate: new Date(),
      type: 'date',
    },
    {
      ...textField('email', 'Email', 'Enter email', false, true, true),
      type: 'email',
    },
    textField('mobile', 'Mobile', 'Enter Mobile', false, true, true),
    {
      name: 'state',
      label: 'State',
      required: true,
      getData: getState,
      async: false,
      descId: 'code',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter state',
    },
    textField('city', 'City', 'Enter city', true),
    {
      name: 'preferred_language_name',
      label: 'Preferred Language',
      id: 'preferred_language',
      desc: 'language_name',
      descId: 'language_name',
      data: masterData.languages,
      type: 'custom_search_select',
      placeholder: 'Enter preferred language',
    },

    {
      ...textField('pan_no', 'PAN Number', 'Enter pan number', false, true),
      toCapitalize: true,
    },

    {
      name: 'account_name',
      label: 'Primary Account',
      required: true,
      getData: getAcccount,
      async: true,
      id: 'account_id',
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      disabled: subSection,
      hidden: subSection && !parentParams?.id,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Primary Account',
      actionLabel: subSection || !checkPermission('add') ? '' : 'Add New',
      handleAction: handleAction,
    },
  ]
  const itemBool = subSection && !parentParams?.id ? true : false
  const formSchema = getMergedSchema(itemBool)

  const methods = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    if (subSection) {
      methods.reset({
        account_name: parentParams?.name,
        account_id: parentParams?.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentParams])

  const handleSubmission = (data: any) => {
    methods.reset({
      first_name: '',
      last_name: '',
      email: '',
      state: '',
      stateId: null,
      city: '',
      preferred_language: null,
      date_of_birth: '',
      pan_no: '',
      mobile: '',
    })

    if (!saveContinue) {
      if (!subSection) {
        navigate(`${router_config.CONTACTS.path}/${data?.id}/summary`)
      }
      handleClearAndClose()
      handleAccountCallBack?.(data)
    } else {
      handleAccountCallBack?.(data)
    }
  }
  const { mutate, data, isLoading } = useCreateContact(handleSubmission)
  const onSubmit = (data: any, saveAndContinue: boolean) => {
    setSaveContinue(saveAndContinue)
    mutate(
      {
        ...data,
        first_name: data?.first_name?.toUpperCase(),
        last_name: data?.last_name?.toUpperCase(),
      },
      {
        onSuccess: (res: any) => {
          saveAndContinue ? handleRefresh?.() : handleSubmission(res.data)
        },
      }
    )
  }
  useEffect(() => {
    if (subSection && data) handleAccountCallBack?.(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleDuplicate = async (fieldName: string, value?: string) => {
    const result = await getContactDuplicateData(fieldName, value)
    setDuplicates({ [fieldName]: result ?? [] })
  }

  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }

  const handleCallback = (res: any) => {
    methods.setValue('account_id', res?.data?.id)
    methods.setValue('account_name', res?.data?.business_name)
  }

  const handleClearAndClose = () => {
    methods.reset({
      first_name: '',
      last_name: '',
      email: '',
      state: '',
      stateId: null,
      city: '',
      preferred_language: null,
      date_of_birth: '',
      pan_no: '',
      mobile: '',
    })
    handleClose()
  }
  console.log(methods.formState.errors)
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        actionLoader={isLoading}
        handleClose={() => handleClearAndClose()}
        handleSaveAndContinue={
          subSection ? undefined : handleSubmit((data) => onSubmit(data, true))
        }
        handleSubmit={handleSubmit((data) => onSubmit(data, false))}
        title="Create New Contact"
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={true}
              duplicates={duplicates}
              duplicateFields={duplicateFields}
              clearDuplicates={handleClearDuplicates}
              getDuplicates={handleDuplicate}
            />
          </FormProvider>
        </div>
      </CustomDrawer>
      <CreateAccount
        isDrawerOpen={isAccountOpen}
        subSection={true}
        from="contact"
        handleCallback={handleCallback}
        handleClose={() => setIsAccountOpen(false)}
      />
    </>
  )
}
