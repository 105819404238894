import { QbsTable } from 'qbs-react-grid'
import { useState } from 'react'

import InfoBox from '../../components/app/alertBox/infoBox'
import { DialogModal } from '../../components/common'
import ListingHeader from '../../components/common/ListingTiles/index'
import { useSnackbarManager } from '../../components/common/snackbar'
import { useTeamFilterStore } from '../../store/filterSore/teamStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { statusClassGen } from '../../utilities/generators'
import { getErrorMessage, getSortedColumnName } from '../../utilities/parsers'
import { deleteSupplier, useSupplier } from './api'

import { getColumns } from './myTaskColumns'
import { checkPermission } from './permission'

const PayoutListing = () => {
  const { enqueueSnackbar } = useSnackbarManager()
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { pageParams, setPageParams } = useTeamFilterStore()
  const { page, page_size, search, ordering } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const { data, refetch, isLaoding, isFetching } = useSupplier({
    ...searchParams,
  })

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const basicData = {
    title: 'Payouts',
    icon: 'user',
  }

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  const handleDelete = () => {
    deleteSupplier(activeRow.id)
      .then(() => {
        enqueueSnackbar('Deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  // const handleDeleteOpen = (row: any) => {
  //   setActiveRow(row)
  //   setDeleteOpen(true)
  // }
  // const handleOpenDrawer = () => {
  //   setOpenDrawer(true)
  // }

  // const handleEdit = (id: string) => {
  //   const getInnerProps = (row: any) => {
  //     const addresses = row?.address || {}

  //     const outputProps: any = {}

  //     let keyword: string
  //     Object.keys(addresses).forEach((key: string) => {
  //       keyword = 'address.' + key
  //       outputProps[key] = getNestedProperty(row, keyword)
  //     })
  //     return {
  //       ...outputProps,
  //       state_name: row?.address?.state,
  //       country_name: row?.address?.country,
  //       category_name: row?.category?.name,
  //       category: row?.category?.id,
  //     }
  //   }
  //   if (id) {
  //     getSupplierDetails(id).then((res) => {
  //       setActiveRow({ ...res, ...getInnerProps(res) })
  //       setEdit(true)
  //       setOpenDrawer(true)
  //     })
  //   }
  // }

  return (
    <div className="con">
      <ListingHeader
        data={basicData}
        // onActionClick={handleOpenDrawer}
        checkPermission={checkPermission}
        actionProps={{ hideExport: true }}
      />

      <div className=" p-4">
        <QbsTable
          columns={
            getColumns({
              // onViewAction: onViewAction,
              statusClassGen: statusClassGen,
            }) as any
          }
          dataRowKey="id"
          isLoading={isLaoding || isFetching}
          toolbar
          search
          data={data?.results ?? []}
          sortType={pageParams.sortType}
          height={calcWindowHeight(303)}
          tableBodyHeight="calc(100vh - 500px)"
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          // actionProps={[
          // {
          //   title: 'View',
          //   action: (rowData) => navigate(onViewAction(rowData)),
          //   icon: <Icons name="eye" />,
          //   hidden: !checkPermission('view'),
          //   toolTip: 'View',
          // },
          // {
          //   title: 'Edit',
          //   action: (row: any) => {
          //     handleEdit(row?.id)
          //   },
          //   icon: <Icons name="edit" />,
          //   hidden: !checkPermission('change'),
          //   toolTip: 'Edit',
          // },
          // {
          //   title: 'Delete Team',
          //   hide: (rowData) =>
          //     blockActionsbyStatus(rowData?.order_status?.code),
          //   action: (rowData) => handleDeleteOpen(rowData),
          //   icon: <Icons name="delete" />,
          //   hidden: !checkPermission('delete'),

          //   toolTip: 'Delete Team',
          // },
          // ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          columnToggle={false}
        />
      </div>

      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={<InfoBox content={'Are you sure you want to delete?'} />}
      />
    </div>
  )
}

export default PayoutListing
