import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { useServicePaymentRequestDetails } from './api'
import { statusClassGen } from '../../../../utilities/generators'
import { getColumns } from './paymentRequestColumns'
import ReviewActivityRequests from '../../../activities/details/requests/review'
import { Button } from '../../../../components/common'
import ManagePaymentRequests from './manage'
import { checkPermission } from './permission'

const ServicePayments = () => {
  const { setActiveTab, detailData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    detailData: any
  }>()
  const [activeRow, setActiveRow] = useState<any>(null)
  const [reviewOpen, setReviewOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const params = useParams()
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching, refetch } = useServicePaymentRequestDetails(
    detailData?.order?.id,
    params?.id as string,
    {}
  )
  const handleCloseAll = () => {
    setReviewOpen(false)
    setActiveRow(null)
    setIsOpen(false)
  }
  const onViewAction = (r: any, from: string) => {
    if (from == 'linkTo') {
      setActiveRow(r)
      setReviewOpen(true)
    }
  }
  const handleAction = () => {
    setIsOpen(true)
  }
  const handleCallback = () => {
    refetch()
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                label={'Add New'}
                hidden={!checkPermission('add')}
                icon={'plus'}
                onClick={handleAction}
              />
            </div>
          }
          columns={
            getColumns({
              onViewAction: onViewAction,
              statusClassGen: statusClassGen,
            }) as any
          }
          columnToggle
        />
        <ManagePaymentRequests
          isDrawerOpen={isOpen}
          service_id={params?.id}
          order_id={detailData?.order?.id}
          handleClose={() => handleCloseAll()}
          handleCallback={handleCallback}
        />
        <ReviewActivityRequests
          isDrawerOpen={reviewOpen}
          data={activeRow}
          disabled={true}
          handleClose={() => handleCloseAll()}
        />
      </div>
    </>
  )
}

export default ServicePayments
