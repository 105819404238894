import { checkPermissionAny } from '../../configs/permissionGate'
import { MODULES } from '../../configs/scopes.config'
const service = MODULES.services
const module = MODULES.activity

export const checkPermission = (slug: string) => {
  const permissionSlug = `${slug}_${module}`

  return checkPermissionAny(permissionSlug)
}
export const checkDetailPermission = (slug: string, subModule?: string) => {
  const permissionSlug = `${slug}_${module}_${subModule}`
  return checkPermissionAny(permissionSlug)
}

export const checkDetailPermissionAssignee = (slug: string) => {
  const permissionSlug = `${slug}_${service}_${module}`

  return checkPermissionAny(permissionSlug)
}
