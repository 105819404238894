import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { getServiceDetails } from '../../api'
import BasicInformation from './basicInformation'
import ParentOrderInformation from './parentOrderInformation'
import PricingInformation from './pricingInformation'
import { checkPermission } from '../../permission'

const ServiceSummary = () => {
  const params = useParams()

  const {
    setActiveTab,
    handleParentCallBack,
    disabled,
    detailData,
    hasServiceLock,
    hasOrderLock,
  } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    disabled: boolean
    detailData?: any
    hasServiceLock?: any
    hasOrderLock?: any
  }>()

  const [data, setData] = useState<any>(detailData)
  const [isDisabled, setIsDisabled] = useState(false)
  useEffect(() => {
    setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setData(detailData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData])

  useEffect(() => {
    setIsDisabled(disabled || hasServiceLock || hasOrderLock)
  }, [disabled, hasServiceLock, hasOrderLock])
  const getData = async () => {
    const data = await getServiceDetails(params.id)
    setData(data?.data)
  }
  const handleRefresh = () => {
    getData()
    handleParentCallBack?.()
  }

  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        disabled={isDisabled || hasServiceLock || hasOrderLock}
        serviceId={params.id ?? params.id}
        handleRefresh={handleRefresh}
        hasPermission={checkPermission('change')}
      />
      <PricingInformation data={data} />
      <ParentOrderInformation data={data} />
    </div>
  )
}

export default ServiceSummary
