import { QbsTable } from 'qbs-react-grid'
import { useEffect } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import Icons from '../../../../components/common/icons/index'
import { router_config } from '../../../../configs/route.config'
import { useAccountFilterStore } from '../../../../store/filterSore/associatedAccountStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useContactAccountDetails } from '../../api'
import { getColumns } from './accountColumns'

const ContactAccounts = () => {
  const navigate = useNavigate()
  // const [isAccountOpen, setIsAccountOpen] = useState(false)
  // const { enqueueSnackbar } = useSnackbarManager()
  // const [unlink, setUnlink] = useState(false)
  // const [linkAccountOpen, setLinkAccountOpen] = useState(false)
  // const [primary, setPrimary] = useState<boolean>(false)
  // const [primaryId, setPrimaryId] = useState<string>('')

  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useAccountFilterStore()

  const params = useParams()

  // const handleAction = () => {
  //   setIsAccountOpen(true)
  // }
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(
    () => {
      setActiveTab('CONTACT_DETAILS_ACCOUNTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { page, page_size, search, ordering } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }

  const { data, isFetching } = useContactAccountDetails(
    params?.id ?? '',
    searchParams
  )
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  const onViewAction = (r: any) => {
    return `${router_config.ACCOUNTS.path}/${r?.company?.id}/summary`
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  // const handleSetPrimaryAccount = () => {
  //   const data = {
  //     company_id: primaryId,
  //   }
  //   if (primaryId) {
  //     setPrimaryAccount(params.id ?? '', data)
  //       .then(() => {
  //         enqueueSnackbar('Primary Account changed successfully', {
  //           variant: 'success',
  //         })
  //         handleCallback?.()
  //         setPrimary(false)
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar(getErrorMessage(err.response.data.error), {
  //           variant: 'error',
  //         })
  //       })
  //   }
  // }

  // const handleSetPrimary = (row: any) => {
  //   setPrimary(true)
  //   setPrimaryId(row?.company?.id)
  // }

  // const handleCallback = () => {
  //   refetch()
  //   handleParentCallback?.()
  // }
  // const handleClose = () => {
  //   setIsAccountOpen(false)
  //   setLinkAccountOpen(false)
  // }
  // const handleLinkAction = () => {
  //   setLinkAccountOpen(true)
  // }
  // const handleRemovePrimary = (row: any) => {
  //   setPrimaryId(row?.id)
  //   setUnlink(true)
  // }
  // const handleSetUnlink = () => {
  //   if (primaryId) {
  //     unLinkAccount(params.id ?? '', primaryId)
  //       .then(() => {
  //         enqueueSnackbar(' Account unlinked  successfully', {
  //           variant: 'success',
  //         })
  //         setPrimary(false)
  //         setUnlink(false)

  //         handleCallback()
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar(getErrorMessage(err.response.data.error), {
  //           variant: 'error',
  //         })
  //       })
  //   }
  // }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          selectedRows={selectedRows}
          // selection={true}
          search={true}
          isLoading={isFetching}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={getColumns({
            onViewAction: onViewAction,
            statusClassGen: statusClassGen,
          })}
          // pagination={true}
          // tableHeaderActions={
          //   <div className="flex gap-2">
          //     <Button
          //       onClick={handleLinkAction}
          //       label={'Link Account'}
          //       outlined={true}
          //       icon="link"
          //       hidden={!checkDetailPermission('link', MODULES.account)}
          //       className="iconWidthSm iconPrimary"
          //     />
          //     <Button
          //       onClick={handleAction}
          //       label={'Add New'}
          //       hidden={
          //         !checkDetailPermission('add', MODULES.account) ||
          //         !checkAccountPermission('add')
          //       }
          //       className="se"
          //     />
          //   </div>
          // }
          // paginationProps={{
          //   onPagination: onChangePage,
          //   total: data?.count,
          //   currentPage: pageParams?.page,
          //   rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          //   onRowsPerPage: onChangeRowsPerPage,
          //   dropOptions: [10, 20, 30, 50]

          //   dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN?JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN):[10, 20, 30, 50],
          // }}
          actionProps={[
            {
              icon: <Icons name="eye" />,
              action: (row) => navigate(onViewAction(row)),
              title: 'view',

              toolTip: 'View',
            },
            //   {
            //     icon: <Icons name="star-icon" />,
            //     action: (row) => handleSetPrimary(row),
            //     hidden: !checkDetailPermission('set', MODULES.account),
            //     title: 'Set as Primary Account',
            //     toolTip: 'Set as Primary Account',
            //     hide: (row) =>
            //       row?.customer.primary_company_id == row.company.id
            //         ? true
            //         : false,
            //   },
            //   {
            //     icon: <Icons name="un-link" />,
            //     action: (row) => handleRemovePrimary(row),
            //     title: 'Set as Primary Account',
            //     hidden: !checkDetailPermission('unlink', MODULES.account),
            //     hide: (row) =>
            //       row?.customer?.primary_company_id === row.company.id,
            //     toolTip: 'Un-link',
            //   },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
      {/* <CreateAccount
        isDrawerOpen={isAccountOpen}
        subSection={true}
        parentPrams={{ id: params?.id }}
        handleClose={() => handleClose()}
        handleCallback={handleCallback}
      />
      <LinkAccount
        isDrawerOpen={linkAccountOpen}
        handleClose={handleClose}
        handleCallback={handleCallback}
        subSection={true}
      />
      <DialogModal
        isOpen={primary}
        onClose={() => setPrimary(false)}
        title={'Are you sure?'}
        onSubmit={() => handleSetPrimaryAccount()}
        secondaryAction={() => setPrimary(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to set this account as the main account for this contact? Please note that only one account can be the primary, and if you proceed, it will replace any existing primary account.'
            }
          />
        }
      />
      <DialogModal
        isOpen={unlink}
        onClose={() => setUnlink(false)}
        title={'Are you sure?'}
        onSubmit={() => handleSetUnlink()}
        secondaryAction={() => setPrimary(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to unlink this account as the primary account for this contact? Please note after unlinking you have to link another account as primary for this contact.'
            }
          />
        }
      /> */}
    </>
  )
}

export default ContactAccounts
