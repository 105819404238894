import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getPaymentDetails } from '../../../../apis/common.apis'
import config from '../../../../config'
import ShareReceipt from './shareReceipt'

const OrderPaymentReceipt = () => {
  const params = useParams()
  const [paymentData, setpaymentData] = useState<any>()
  const [shareReceipt, setshareReceipt] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>(false)

  useEffect(
    () => {
      getData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getData = () => {
    setIsLoading(true)
    getPaymentDetails(params?.id).then((res) => {
      setIsLoading(false)
      setpaymentData(res.data)
    })
  }
  // const onDownload = (r: any) => {
  //   if (r?.receipt) {
  //     downloadFromServer(r.receipt, r.display_file_name)
  //   }
  // }
  return (
    <div className="p-4">
      {!isLoading &&
        (!paymentData ? (
          <div className="flex flex-col items-center justify-center gap-2 py-10 min-h-[calc(100vh-300px)]">
            <img
              src={config.EMPTY_ILLUSTRATION}
              alt="bizpole"
              className="h-auto"
            />
            <p className="text-sm font-bold text-primaryText">
              No Receipt available
            </p>
            <p className="text-sm">
              Oops! Receipt for this payment is not yet generated at the moment
            </p>
          </div>
        ) : (
          <div className="template_Wrapper  bg-bgGrey p-4 max-w-7xl w-full mx-auto rounded-lg ">
            <div className="flex items-center justify-between mb-3">
              <h4 className=" text-lg text-primaryText font-bold">Receipt</h4>
              {/* <div className="flex gap-2 items-center justify-end"> */}
              {/* <Button
                  label={'Share'}
                  hidden={
                    !checkDetailPermission('share', MODULES.receipt) ||
                    paymentData?.payment_verification_status?.code ===
                      'not_verified' ||
                    blockActionsbyStatus(
                      paymentData?.order?.order_status?.code,
                      ['cancelled', 'converted', 'rejected', 'order_cancelled']
                    )
                  }
                  icon={'share-alt'}
                  outlined={true}
                  onClick={() => setshareReceipt(true)}
                  size="xs"
                /> */}
              {/* {paymentData?.receipt &&
                  checkDetailPermission('download', MODULES.receipt) && (
                    <Button
                      size="xs"
                      icon={'download'}
                      hidden={
                        !checkDetailPermission('download', MODULES.receipt) ||
                        paymentData?.payment_verification_status?.code ===
                          'not_verified' ||
                        blockActionsbyStatus(
                          paymentData?.order?.order_status?.code,
                          [
                            'cancelled',
                            'converted',
                            'rejected',
                            'order_cancelled',
                          ]
                        )
                      }
                      label={'Download'}
                      onClick={() => {
                        onDownload(paymentData)
                      }}
                    />
                  )}
              </div> */}
            </div>
            <div
              className="template_Block"
              dangerouslySetInnerHTML={{ __html: paymentData?.html_view }}
            ></div>
          </div>
        ))}

      <ShareReceipt
        isDrawerOpen={shareReceipt}
        handleClose={() => setshareReceipt(false)}
        id={params.id}
      />
    </div>
  )
}

export default OrderPaymentReceipt
