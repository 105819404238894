import { useEffect, useState } from 'react'

import { getNotificationList } from '../apis/common.apis'
import CreateTask from '../components/app/task/create'
import { Button } from '../components/common'
import Icons from '../components/common/icons/index'
import { checkPermissionAny } from '../configs/permissionGate'
import { useAppStore } from '../store/appStore'
import { useAuthStore } from '../store/authStore'
import { useClearFilter } from '../store/filterSore/clearStore'
// import FranchiseeMenu from './franchiseeMenu'
import HeaderMenu from './headerMenu'
import NotificationList from './notificationList'
import SwitchEntities from './switchEntities'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value?.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
  }
}

export default function Header() {
  const {
    clearAuthenticated,
    userData,
    // setUserData,
    // impersonating,
    // setImpersonating,
    // actualeUser,
    // setPermissionData,
    // setFranchisee,
    setActualUser,
  } = useAuthStore()
  const { openNotification } = useAppStore()

  const handleClear = useClearFilter()
  const [openEntity, setOpenEntity] = useState(false)
  const [openTask, setOpenTask] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [notificationSearch, setNotificationSearch] = useState({
    page_size: 30,
    page: 1,
  })
  const [hasMore, setHasMore] = useState(false)

  const [notificationData, setNotificationData] = useState<any>([])
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const [notificationFilter, setNotificationFilter] = useState<any>()
  const [model, setModel] = useState<string | undefined>(undefined)
  const handleLogout = () => {
    setActualUser({})
    // setImpersonating(false)
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('shouldReload', 'false')
    clearAuthenticated()
    handleClear()
  }
  const handleCloseEntity = () => {
    setOpenEntity(false)
  }
  // const { enqueueSnackbar } = useSnackbarManager()
  // const handleStopImpersonating = () => {
  //   setImpersonating(false)
  //   localStorage.setItem('shouldReload', 'false')
  //   getImpersonate(actualeUser?.id ?? '')
  //     .then((res) => {
  //       setPermissionData(res.data?.role?.permissions)
  //       setUserData({
  //         ...res.data?.user,
  //         is_admin: true,
  //         name: res.data.name,
  //         is_operations_head: res.data.is_operations_head,
  //       })
  //       setFranchisee({
  //         ...res?.data?.franchisee,
  //         is_default_franchisee: true,
  //       })
  //       setImpersonating(false)
  //       window.open('dashboard', '_self')
  //       localStorage.setItem('shouldReload', 'true')
  //     })
  //     .catch((err) => {
  //       enqueueSnackbar(err.response.data.message, { variant: 'error' })
  //     })
  // }
  const handleCloseTask = () => {
    setModel(undefined)
    setOpenTask(false)
  }

  const handleDefaultTask = () => {
    setModel(undefined)
    handleOpenTask()
  }
  const handleOpenTask = () => {
    setOpenTask(true)
  }
  const droDownManager = (data: any) => {
    setModel(data?.modal_name)
    setOpenEntity(true)
  }

  const handleShowHeaderButton = () => {
    return checkPermissionAny('manage_generaltask')
    // checkLeadPermission('add') ||
    // checkDealPermission('add') ||
    // checkProspectPermission('add') ||
    // checkQuotePermission('add') ||
    // checkAccountPermission('add') ||
    // checkContactPermission('add') ||
    // checkOrderPermission('add')
  }

  const requestCache = new Map()
  const handlePage = (key?: string) => {
    switch (key) {
      case 'first':
        return 1
      case 'current':
        return Math.max(notificationSearch.page - 1, 1)
      default:
        return notificationSearch.page
    }
  }

  const fetchNotifications = async (newSearchParams: any) => {
    return await getNotificationList(newSearchParams)
  }

  const updateNotificationData = (data: any, key?: string) => {
    setNotificationCount(data.count)

    if (data.results?.length > 0) {
      setNotificationData((prevItems: any) =>
        updateItems(prevItems, data.results, key)
      )
    } else {
      setNotificationData([])
    }
  }
  const updateItems = (prevItems: any, results: any, key?: string) => {
    if (key === 'first') {
      return results
    }
    const newItemsMap = new Map(prevItems.map((item: any) => [item.id, item]))
    results.forEach((item: any) => newItemsMap.set(item.id, item))
    return Array.from(newItemsMap.values())
  }

  const handlePagination = (data: any, key?: string) => {
    const hasMoreItems = data?.next !== null
    setHasMore(hasMoreItems)

    if (hasMoreItems) {
      setNotificationSearch((prevState) => ({
        ...prevState,
        page: key === 'first' ? 2 : prevState.page + 1,
      }))
    }
  }

  const getNotification = async (key?: string) => {
    const newPage = handlePage(key)
    const newSearchParams = {
      franchisee_id: notificationFilter,
      ...notificationSearch,
      page: newPage,
    }

    const cacheKey = JSON.stringify(newSearchParams)

    if (
      isLoading ||
      requestCache.get(cacheKey) ||
      (!hasMore && newPage !== 1)
    ) {
      return
    }

    setIsLoading(true)
    requestCache.set(cacheKey, true)

    try {
      const { data } = await fetchNotifications(newSearchParams)
      updateNotificationData(data, key)
      handlePagination(data, key)
    } catch (error) {
      console.error('Failed to fetch notifications:', error)
    } finally {
      setIsLoading(false)
      requestCache.delete(cacheKey)
    }
  }

  useEffect(() => {
    getNotification()
  }, [notificationFilter, openNotification])

  const handleNotificationClose = () => {
    setNotificationFilter(undefined)
    setNotificationData([])
    setHasMore(false)
    setNotificationSearch({ page: 1, page_size: 30 })
    setIsDrawerOpen(false)
  }
  const handleOpenNotification = () => {
    setNotificationData([])
    setIsDrawerOpen(!isDrawerOpen)
    setHasMore(false)
    getNotification('first')
  }
  return (
    <>
      <header
        className={`
      bg-white flex items-center justify-end
         dark:bg-gray-700 dark:text-white   text-gray-700  border-b border-divider py-3 px-4  z-10 h-16`}
      >
        {/* <div className="flex relative">
          <FranchiseeMenu />
        </div> */}
        {/* {impersonating && (
          <div className="flex justify-center items-center">
            <p className=" text-white text-lg">
              {`You are impersonating ${userData?.name}   `}
              <span
                className=" underline cursor-pointer"
                onClick={handleStopImpersonating}
              >
                Exit Now
              </span>
            </p>
          </div>
        )} */}
        <div className="flex  gap-3 items-center justify-end">
          <span
            onClick={() => handleOpenNotification()}
            className={`relative flex flex-col items-center border rounded-full p-2 cursor-pointer ${notificationCount > 0 && '  border-primary'}`}
          >
            <Icons name="notify-icon-big" />
            {notificationCount > 0 && (
              <div className="absolute top-1 right-1  transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-[9px] font-medium  rounded-full h-[22px] w-[22px] flex items-center justify-center">
                {notificationCount > 99 ? '99+' : notificationCount}
              </div>
            )}
          </span>

          {handleShowHeaderButton() && (
            <div className="customDropButton">
              <Button
                icon="plus"
                size="md"
                // className={`${impersonating && ' bg-transparent'}`}
                hidden={true}
                dropdown={{
                  onClick: droDownManager,
                  items: [
                    {
                      label: 'Task',
                      icon: 'mytasks-icon',
                      modal_name: 'Task',
                      hidden: !checkPermissionAny('manage_generaltask'),
                    },
                    // {
                    //   label: 'Leads',
                    //   icon: 'leads-icon',
                    //   modal_name: 'Lead',
                    //   hidden: !checkLeadPermission('add'),
                    // },
                    // {
                    //   label: 'Prospects',
                    //   icon: 'prospect',
                    //   modal_name: 'Prospect',
                    //   hidden: !checkProspectPermission('add'),
                    // },
                    // {
                    //   label: 'Deals',
                    //   icon: 'deals',
                    //   modal_name: 'Deal',
                    //   hidden: !checkDealPermission('add'),
                    // },

                    // {
                    //   label: 'Quotes',
                    //   icon: 'quote',
                    //   modal_name: 'Quote',
                    //   hidden: !checkQuotePermission('add'),
                    // },
                    // {
                    //   label: 'Accounts',
                    //   icon: 'building-icon',
                    //   modal_name: 'CustomerCompany',
                    //   hidden: !checkAccountPermission('add'),
                    // },
                    // {
                    //   label: 'Contacts',
                    //   icon: 'user',
                    //   modal_name: 'Customer',
                    //   hidden: !checkContactPermission('add'),
                    // },
                    // {
                    //   label: 'Orders',
                    //   icon: 'cart-icon',
                    //   modal_name: 'Order',
                    //   hidden: !checkOrderPermission('add'),
                    // },
                  ],
                }}
                onClick={
                  checkPermissionAny('add_generaltask')
                    ? handleDefaultTask
                    : undefined
                }
              />
            </div>
          )}
          {/* <div className=" w-9 h-9 flex items-center justify-center">
            <ThemeSwitcher />
          </div> */}

          <HeaderMenu userData={userData} handleLogout={handleLogout} />
        </div>
      </header>
      <CreateTask
        isDrawerOpen={openTask}
        model_name={model}
        handleClose={() => handleCloseTask()}
        isGeneral={true}
      />
      {isDrawerOpen && (
        <NotificationList
          handleClose={handleNotificationClose}
          open={isDrawerOpen}
          notificationData={notificationData}
          getNotification={getNotification}
          setNotificationFilter={setNotificationFilter}
          setNotificationData={setNotificationData}
          notificationFilter={notificationFilter}
          isLoading={isLoading}
          setHasMore={setHasMore}
          hasMore={hasMore}
          setIsloading={setIsLoading}
          notificationCount={notificationCount}
          setNotificationSearch={setNotificationSearch}
        />
      )}
      {openEntity && (
        <SwitchEntities
          key={model}
          open={openEntity}
          model={model}
          handleClose={handleCloseEntity}
        />
      )}
    </>
  )
}
