import { useAccountFilterStore } from './accountStore'
import { useActivitiesFilterStore } from './activitiesStore'
import { useActivityFilterStore } from './activityStore'
import { useAttachmentFilterStore } from './attachmentStore'
import { useBusinessSectorFilterStore } from './businessSectorStore'
import { useConstitutionFilterStore } from './constitutionStore'
import { useContactFilterStore } from './contactStore'
import { useAgentStatusReportFilterStore } from './creStatusReportStore'
import { useDealFilterStore } from './dealStore'
import { useProspectFilterStore } from './ProspectStore'

import { useEmployeeFilterStore } from './employeeStore'
import { useFranchiseeEmployeeFilterStore } from './franchiseeEmployeeStore'
import { useFranchiseeServiceFilterStore } from './franchiseeServiceStore'
import { useFranchiseeFilterStore } from './franchiseeStore'
import { useInvoicesFilterStore } from './invoiceStore'
import { useLeadFilterStore } from './leadStore'
import { useMyApprovalStore } from './myApprovalStore'
import { useMyPaymentApprovalStore } from './myPaymentApprovalStore'
import { useMyTaskFilterStore } from './myTaskStore'
import { useOrderFilterStore } from './orderStore'
import { usePaymentsFilterStore } from './paymentStore'
import { useQuoteFilterStore } from './quoteStore'
import { useRoleFilterStore } from './roleStore'
import { useServiceCategoryFilterStore } from './serviceCategoriesStore'
import { useServiceManagementFilterStore } from './serviceManagementStore'
import { useServiceFilterStore } from './serviceStore'
import { useStakeholderFilterStore } from './stakeholderStore'
import { useTeamFilterStore } from './teamStore'
import { useNotificationStore } from './notificationStore'
import { usePayoutFilterStore } from './payoutStore'

export function useClearFilter(type?: string) {
  const accountFilterStore = useAccountFilterStore()
  const roleFilterStore = useRoleFilterStore()
  const contactFilterStore = useContactFilterStore()
  const invoiceFilterStore = useInvoicesFilterStore()
  const orderFilterStore = useOrderFilterStore()
  const paymentFilterStore = usePaymentsFilterStore()
  const serviceFilterStore = useServiceFilterStore()
  const leadFilterStore = useLeadFilterStore()
  const dealFilterStore = useDealFilterStore()
  const prospectFilterStore = useProspectFilterStore()

  const myTaskFilterStore = useMyTaskFilterStore()
  const quoteFilterStore = useQuoteFilterStore()
  const approvalStore = useMyApprovalStore()
  const paymentApprovalStore = useMyPaymentApprovalStore()
  const agentStatusReportFilterStore = useAgentStatusReportFilterStore()
  const activityStore = useActivityFilterStore()
  const employeeFilterStore = useEmployeeFilterStore()
  const teamFilterStore = useTeamFilterStore()
  const constitutionFilterStore = useConstitutionFilterStore()
  const serviceCategoryFilterStore = useServiceCategoryFilterStore()
  const businessSectorFilterStore = useBusinessSectorFilterStore()
  const franchiseeFilterStore = useFranchiseeFilterStore()
  const emloyeeFilterStore = useEmployeeFilterStore()
  const stakeholderFilterStore = useStakeholderFilterStore()
  const serviceManagementFilterStore = useServiceManagementFilterStore()
  const franchiseeServiceFilterStore = useFranchiseeServiceFilterStore()
  const franchiseeEmployeeFilterStore = useFranchiseeEmployeeFilterStore()
  const activitiesFilterStore = useActivitiesFilterStore()
  const attachmentFilterStore = useAttachmentFilterStore()
  const notificationStore = useNotificationStore()
  const payoutFilterStore = usePayoutFilterStore()

  const stores = [
    {
      store: franchiseeEmployeeFilterStore,
      clearFilter: 'clearFranchiseeEmployeeFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: franchiseeServiceFilterStore,
      clearFilter: 'clearFranchiseeServiceFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: franchiseeFilterStore,
      clearFilter: 'clearFranchiseeFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: emloyeeFilterStore,
      clearFilter: 'clearOrganizationEmployeeFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: accountFilterStore,
      clearFilter: 'clearAccountFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: approvalStore,
      clearFilter: 'clearMyApprovalFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: paymentApprovalStore,
      clearFilter: 'clearMyPaymentApprovalFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: contactFilterStore,
      clearFilter: 'clearContactFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: invoiceFilterStore,
      clearFilter: 'clearInvoiceFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: orderFilterStore,
      clearFilter: 'clearOrderFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: paymentFilterStore,
      clearFilter: 'clearPaymentFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: serviceFilterStore,
      clearFilter: 'clearServiceFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: leadFilterStore,
      clearFilter: 'clearLeadFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: dealFilterStore,
      clearFilter: 'clearDealFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: prospectFilterStore,
      clearFilter: 'clearProspectFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },

    {
      store: myTaskFilterStore,
      clearFilter: 'clearMyTaskFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: quoteFilterStore,
      clearFilter: 'clearQuoteFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: agentStatusReportFilterStore,
      clearFilter: 'clearAgentStatusReportFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: activityStore,
      clearFilter: 'clearActivityFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: roleFilterStore,
      clearFilter: 'clearRoleFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: employeeFilterStore,
      clearFilter: 'clearEmployeeFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: teamFilterStore,
      clearFilter: 'clearTeamFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: constitutionFilterStore,
      clearFilter: 'clearConstitutionFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: serviceCategoryFilterStore,
      clearFilter: 'clearServiceFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: businessSectorFilterStore,
      clearFilter: 'clearBusinessFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: stakeholderFilterStore,
      clearFilter: 'clearBusinessFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: serviceManagementFilterStore,
      clearFilter: 'clearServiceManagementFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: activitiesFilterStore,
      clearFilter: 'clearActivitiesFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: attachmentFilterStore,
      clearFilter: 'clearAttachmentFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: notificationStore,
      clearFilter: 'clearNotificationFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
    {
      store: payoutFilterStore,
      clearFilter: 'clearPayoutFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
  ]
  if (type == 'filter_only') {
    return () => {
      stores.forEach(({ store, clearFilter, clearAdvacneFilter }) => {
        if (typeof (store as any)[clearFilter] === 'function') {
          ;(store as any)[clearFilter]('from_filter')
        }
        if (typeof (store as any)[clearAdvacneFilter] === 'function') {
          ;(store as any)[clearAdvacneFilter]()
        }
      })
    }
  } else {
    return () => {
      stores.forEach(
        ({ store, clearFilter, clearRows, clearAdvacneFilter }) => {
          if (typeof (store as any)[clearFilter] === 'function') {
            ;(store as any)[clearFilter]()
          }
          if (typeof (store as any)[clearRows] === 'function') {
            ;(store as any)[clearRows]()
          }
          if (typeof (store as any)[clearAdvacneFilter] === 'function') {
            ;(store as any)[clearAdvacneFilter]()
          }
        }
      )
    }
  }
}
export {}
